import userApi from './api/user';
import { generateAuthActions } from 'redux-token-auth';

const config = {
  authUrl: `${process.env.REACT_APP_END_POINT}/api/v2/recruit_employee/auth`,
  userAttributes: {
    firstName: 'name',
    imageUrl: 'image',
  },
  userRegistrationAttributes: {
    name: 'name',
  },
};
// 認証関連のアクション
const {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
} = generateAuthActions(config);

async function resetUserPassword(email) {
  // エラーハンドリングする
  const res = await userApi.resetPassword(email);
  return res.data
}

async function updateNewPassword(data) {
  const res = await userApi.updatePassword(data)
}

export {
  registerUser,
  signInUser,
  signOutUser,
  verifyCredentials,
  resetUserPassword,
  updateNewPassword
};

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import queryString from 'query-string';
import { googleAnalytics } from '../../utils/pageGA';

import { fetchStudents } from '../../actions/students';
import { toggleForm, chatToggleForm } from '../../actions/message';
import OfferingStudents from '../../components/OfferingStudents';
import HocIndicator from '../../components/common/HocIndicator';

const mapStateToProps = (state) => ({
  students: state.students,
  users_size:
    state.students.length > 0 ? state.students[0].attributes.users_size : 0,
  search_students: state.search_students,
  indicatorFlg: true,
  message_flg: state.message_flg,
  chat_flg: state.chat_flg,
});
const mapDispatchToProps = (dispatch) => ({
  fetchStudents: (page, row_count) => dispatch(fetchStudents(page, row_count)),
  toggleForm: () => dispatch(toggleForm()),
  chatToggleForm: () => dispatch(chatToggleForm()),
});

const undefinedHandle = (val) => val === 'undefined' ? "" : val

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      googleAnalytics({
        pathname: this.props.history.location.pathname,
      });
      const result = queryString.parse(this.props.history.location.search);
      const [pageNum, rowPerPage, searchKey] = [
        result['pageNum'],
        result['rowPerPage'],
        {
          ...Object.entries(result).reduce((prev, current) => {
            prev[current[0]] = undefinedHandle(current[1])
            return prev},{}
          )
        },
      ];
      this.props.fetchStudents([pageNum, rowPerPage, searchKey]).then(() => {
        this.setState({ indicatorFlg: false });
      });
    },
  })
)(HocIndicator(OfferingStudents));

import { RECRUIT_ITEMS } from "../actions/recruitmentItems";

export function recruitment_items(state = [], action) {
  switch (action.type) {
    case RECRUIT_ITEMS:
      return action.recruitment_items;
    default:
      return state;
  }
}

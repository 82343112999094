import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  IconButton,
  Collapse,
  Typography,
} from '@material-ui/core';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CleanHtml from '../common/CleanHtml';

const messageTime = (createTime) => {
  return moment(createTime).calendar();
};

const ListItem = (props) => {
  const { classes, children } = props;
  const content = props.content;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Card className={classes.card} elevation={4} style={{}}>
        <div className={classes.details}>
          <CardContent
            className={classes.content}
            style={{ padding: '16px 32px' }}
          >
            <Typography
              component='h6'
              variant='subtitle1'
              style={{ fontsize: 14 }}
              gutterBottom
            >
              {messageTime(content.created_at)}
            </Typography>

            <Typography
              component='h6'
              variant='h6'
              style={{
                fontWeight: 'bold',
                borderBottom: 'solid 1px rgb(96 96 96)',
              }}
              gutterBottom
            >
              <CleanHtml dirtyHtml={content.title} />
            </Typography>
            <div
              style={{
                fontSize: '0.9rem',
                fontWeight: 600,
                color: 'rgb(96 96 96)',
                lineHeight: 1.6,
              }}
            >
              <CleanHtml dirtyHtml={content.content} />
            </div>
          </CardContent>
          <div className={classes.controls} style={{ textAlign: 'right' }}>
            {children}
            <Badge badgeContent={content.comments.length} color='primary'>
              <ChatBubbleIcon />
            </Badge>

            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </IconButton>

            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <div
                className={classes.formControl}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div style={{ margin: 8 }}></div>

                {content.comments.map((comment, i) => (
                  <div style={{ width: '90%' }}>
                    <CardContent
                      className={classes.comment}
                      key={i}
                      style={
                        comment.name === props.name
                          ? { background: 'rgba(226, 50, 90, 0.1)' }
                          : null
                      }
                    >
                      <div className={classes.flex}>
                        <Avatar
                          src={comment.avatarImage}
                          style={
                            comment.name === props.name
                              ? {
                                  width: 80,
                                  height: 80,
                                  position: 'absolute',
                                  top: -33,
                                  right: -33,
                                }
                              : {
                                  width: 80,
                                  height: 80,
                                  position: 'absolute',
                                  top: -33,
                                  left: -33,
                                }
                          }
                        />

                        <Typography
                          variant='span'
                          className={classes.commentHeader}
                          style={
                            comment.name === props.name
                              ? { right: 40 }
                              : { left: 40 }
                          }
                        >
                          {+comment.role === 4 ? 'メンター: ' : '受講生: '}
                          {comment.name}
                        </Typography>
                      </div>

                      <Typography
                        style={{
                          fontSize: '.9rem',
                          textAlign: 'left',
                          whiteSpace: 'pre-line',
                          fontWeight: 600,
                          color: 'rgb(96 96 96)',
                        }}
                        >
                          <CleanHtml dirtyHtml={comment.content} />
                        </Typography>
                    </CardContent>
                  </div>
                ))}
                <div style={{ margin: 24 }}></div>
              </div>
            </Collapse>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ListItem;

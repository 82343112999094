import React from 'react';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';

const BookMark = ({ student, favoriteHandler, classes }) => {
  return (
    <>
      {student.isBookMark ? (
        <TurnedInIcon
          onClick={favoriteHandler}
          className={classes.bookMarkPosition}
        />
      ) : (
        <TurnedInNotIcon
          onClick={favoriteHandler}
          className={classes.bookMarkPosition}
        />
      )}
    </>
  );
};

export default BookMark;

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { getInbox, searchInbox } from '../../actions/chat';
import MessageBox from '../../components/Message/MessageBox';
import HocIndicator from '../../components/common/HocIndicator';

import { googleAnalytics } from '../../utils/pageGA';

const mapStateToProps = (state) => ({
  indicatorFlg: true,
  inboxes: state.inboxes,
  search_inboxes: state.search_inboxes,
});

const mapDispatchToProps = (dispatch) => ({
  getInbox: () => dispatch(getInbox()),
  searchHandler: (query, inboxes) => dispatch(searchInbox(query, inboxes)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      googleAnalytics({
        pathname: this.props.history.location.pathname,
      });
      this.props.getInbox().then((res) => {
        this.setState({ indicatorFlg: false });
      });
    },
  })
)(HocIndicator(MessageBox));

import jobOfferApi from '../api/jobOffer';

export const JOB_OFFERS = 'JOB_OFFERS';
export const JOB_OFFER = 'JOB_OFFER';
export const JOB_OFFER_CREATED = 'JOB_OFFER_CREATED';
export const RESET_JOB_OFFER = 'RESET_JOB_OFFER';
export const JOB_OFFER_UPDATE = 'JOB_OFFER_UPDATE';

export function fetchJobOffers() {
  return async dispatch => {
    try {
      const res = await jobOfferApi.getAll();
      dispatch(receiveJobOffers(res.data.data));
    } catch (err) {
      return err;
    }
  };
}
export function createJobOffer(data) {
  return async dispatch => {
    const res = await jobOfferApi.create({...data});
    res.data.status === 422
      ? dispatch(updateState(res.data))
      : dispatch(createdState(res.data));
  };
}

export function updateJobOffer(data) {
  return async dispatch => {
    const res = await jobOfferApi.update({...data});
    res.data.status === 422
      ? dispatch(updateState(res.data))
      : dispatch(createdState(res.data));
  };
}

export function resetJobOffer() {
  return dispatch => {
    dispatch(resetState());
  };
}

export function fetchJobOffer(Id) {
  return async dispatch => {
    try {
      const res = await jobOfferApi.show(Id);
      dispatch(receiveJobOffer(res.data.data));
    } catch (err) {
      return err;
    }
  };
}

export function fetchJobOfferEdit(Id) {
  return async dispatch => {
    const res = await jobOfferApi.edit(Id);
    dispatch(receiveJobOffer(res.data.data));
  };
}

export function deleteJobOffer(Id) {
  return async dispatch => {
    jobOfferApi.delete(Id);
  };
}

export function editJobOfferForm(data) {
  return dispatch => {
    dispatch(receiveJobOffer({ attributes: { ...data } }));
  };
}

export function changeAttr(attr) {
  return dispatch => {
    dispatch(updateState(attr));
  };
}

function receiveJobOffers(job_offers) {
  return {
    type: JOB_OFFERS,
    job_offers
  };
}

function receiveJobOffer(job_offer) {
  return {
    type: JOB_OFFER,
    job_offer
  };
}

function updateState(attr) {
  return {
    type: JOB_OFFER_UPDATE,
    attr
  };
}

function createdState(attr) {
  return {
    type: JOB_OFFER_CREATED,
    attr
  };
}

function resetState() {
  return {
    type: RESET_JOB_OFFER
  };
}

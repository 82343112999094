import React from 'react';
import {
  createMarkup
} from '../../utils/CleanHtml';

export default function CleanHtml(props) {
  const { dirtyHtml } = props;
  return (
    <div dangerouslySetInnerHTML={createMarkup(dirtyHtml)} />
  )
}

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';
import storage from 'store2';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { updateNewPassword } from '../../redux-token-auth';
import { googleAnalytics } from '../../utils/pageGA';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: 'red',
  },
  goBackLink: {
    marginTop: '5%',
    cursor: 'pointer'
  },
  fullWidth: {
    width: '100%'
  }
});

class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    // custom rule will have name 'isPasswordMatch'
    if (!ValidatorForm.hasValidationRule('isPasswordMatch')) {
      ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        const { password } = this.state;
        if (value !== password) {
            return false;
        }
        return true;
      });
    }

    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      has_error: false
    };
  }

  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  onChangeConfirmPassword = (e) => {
    this.setState({ password_confirmation: e.target.value });
  };
  submitForm = (e) => {
    e.preventDefault();
    const { password, password_confirmation } = this.state;
    const email = storage.local.get('uid');
    // password更新に変える
    updateNewPassword({ email, password, password_confirmation })
      .then(() => {
        this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({ has_error: true });
      });
  };
  componentDidMount() {
    const params = queryString.parse(window.location.search)
    storage.local.set('access-token', params['client[access-token]']);
    storage.local.set('client', params['client[client]']);
    googleAnalytics({
      pathname: this.props.history.location.pathname,
    });
  }
  render() {
    const { classes } = this.props;
    const { has_error, password, password_confirmation } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            DIVE INTO WORK
          </Typography>
          {has_error && <p className={classes.error}>入力に誤りがあります。</p>}
          <>
            <ValidatorForm className={classes.form}
                           onSubmit={this.submitForm}
            >
              <FormControl margin='normal' required fullWidth>
                <TextValidator
                  label="新しいパスワード"
                  id='password'
                  name='password'
                  type="password"
                  validators={['required']}
                  errorMessages={['この入力欄は必須です']}
                  value={password}
                  autoFocus
                  onChange={this.onChangePassword}
                  className={classes.fullWidth}
                />
              </FormControl>
              <FormControl margin='normal' required fullWidth>
                <TextValidator
                  label="新しいパスワード(確認)"
                  id='password_confirmation'
                  name='password_confirmation'
                  type="password"
                  validators={['isPasswordMatch', 'required']}
                  errorMessages={['パスワードが一致しません', 'この入力欄は必須です']}
                  value={password_confirmation}
                  onChange={this.onChangeConfirmPassword}
                  className={classes.fullWidth}
                />
              </FormControl>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                パスワードを変更する
              </Button>
            </ValidatorForm>
            <a onClick={(e) =>
              e.preventDefault
              (this.setState({ displayResetForm: false }))
            }
              className={classes.goBackLink}
            >
              ログイン画面に戻る
            </a>
          </>
        </Paper>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, { updateNewPassword })
)(PasswordReset);

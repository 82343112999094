import React from 'react';

import { Typography, Box } from '@material-ui/core';

const TabPanel = props => {
  const { children, value, index } = props;

  return (
    <Typography component="div">
      {value === index && <Box component={'span'}>{children}</Box>}
    </Typography>
  );
};

export default TabPanel;

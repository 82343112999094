export default {
  toHours(time) {
    return Math.floor((time / 60000 / 60) * 10) / 10;
  },
  toMinutes(time) {
    return Math.floor((time / 60000) * 100) / 100;
  },
  toMillisecond(hour, min) {
    return (hour * 60000 * 60) + (min * 60000);
  },
  stringConvertToMillsecond(time) {
    return parseFloat(time) * 60000;
  },
  toUsualHour(millisecond) {
    const hour = millisecond / (60000 * 60);
    const min = millisecond % (60000 * 60);
    return {
      'hour': Math.floor(hour),
      'min': Math.floor((min / 60000) * 100) / 100,
    };
  },
  toDate(time) {
    return new Date(time).toLocaleDateString();
  },
};


import {
  JOB_OFFERS,
  JOB_OFFER,
  JOB_OFFER_UPDATE,
  JOB_OFFER_CREATED,
  RESET_JOB_OFFER
} from '../actions/jobOffers';

const defaultState = {
  development_environment: "",
  content: "",
  support_system: "",
  day_flow: "",
  work_style: ""
}

export function job_offers(state = [], action) {
  switch (action.type) {
    case JOB_OFFERS:
      return action.job_offers.map(offer => offer.attributes);
    default:
      return state;
  }
}

export function job_offer(state = defaultState, action) {
  switch (action.type) {
    case JOB_OFFER:
      return Object.entries(action.job_offer.attributes).reduce((prev, current) => {
        prev[`${current[0]}`] = current[1] === null ? "" : current[1];
        return prev
      }, {})
    case JOB_OFFER_UPDATE:
      return Object.assign(
        {},
        {
          ...state,
          ...action.attr
        }
      );
    case JOB_OFFER_CREATED:
      // 求人が作成もしくは更新された時に呼ばれる
      // idはshowのページに移動するために必要
      return Object.entries(action.attr).reduce((prev, current) => { prev[`${current[0]}`] = current[1] || ""; return prev}, { id: action.attr.id, created: true })
    case RESET_JOB_OFFER:
      return {};
    default:
      return state;
  }
}

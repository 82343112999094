import API_CLIENT from '../utils/HttpClient';

export default {
  createChatRoom(userId) {
    const formData = new FormData();
    formData.append('chat_room[user_id]', userId);
    return API_CLIENT({
      method: 'POST',
      url: '/api/v2/recruit/chat_rooms',
      data: formData
    });
  },
  getInbox() {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/chat_rooms/inbox'
    });
  },
  fetchChatMessages(chat_room_id) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/chat_rooms/${chat_room_id}/chat_messages`
    });
  },
  createMessage(data) {
    const { message, room_id, chatRoomId, file } = data;
    const formData = new FormData();
    formData.append('chat_message[message]', message);
    formData.append('chat_message[scout_chat_room_id]', room_id);
    formData.append('chat_message[user_id]', chatRoomId);
    if (typeof file !== 'undefined') {
      formData.append('chat_message[images_attributes][0][picture]', file);
    }
    return API_CLIENT({
      method: 'POST',
      url: `/api/v2/recruit/chat_rooms/${room_id}/chat_messages`,
      data: formData
    });
  },
  fetchUnreadCount() {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/chat_rooms/notice_count'
    });
  }
};

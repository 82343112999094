import axios from 'axios';
import storage from 'store2';

const API_CLIENT = axios.create({ baseURL: process.env.REACT_APP_END_POINT });

API_CLIENT.interceptors.request.use((config) => {
  config.headers = getHeader();
  return config;
});

export const setupAxios = {
  setupInterceptors: (store, history) => {
    API_CLIENT.interceptors.response.use(
      (response) => {
        !!response.headers['access-token'] && setHeader(response);
        return response;
      },
      (error, res) => {
        // NOTE: パスワード再発行するための処理のエラーで使っている
        // data.successなどを重複して使わないようにする
        if(error.response.data.errors && !error.response.data.success) {
          return {
            data: {
              ...error.response.data,
              message: error.response.data.errors[0]
            }
          }
        }
        if (!error.response) return (window.location = '/Error');

        switch (error.response.status) {
          case 404:
            window.location.href = '/Error';
            break;
          case 401:
            storage.clearAll();
            window.location.href = '/signin';
          // eslint-disable-next-line no-fallthrough
          default:
            storage.clearAll();
            window.location.href = '/signin';
        }
      }
    );
  },
};

function getHeader() {
  const headers = {
    'access-token': storage.local.get('access-token'),
    'token-type': 'Bearer',
    'Cache-Control': 'no-cache',
    client: storage.local.get('client'),
    expiry: storage.local.get('expiry'),
    uid: storage.local.get('uid'),
  };
  return headers;
}

function setHeader(res) {
  storage.local.set('access-token', res.headers['access-token']);
  storage.local.set('client', res.headers.client);
  storage.local.set('token-type', res.headers['token-type']);
  storage.local.set('expiry', res.headers.expiry);
  storage.local.set('uid', res.headers.uid);
}

export default API_CLIENT;

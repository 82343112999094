import React from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: '40px',
    width: '95%'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  cover: {
    width: '24%',
    height: 222,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  }
}));

const PortfolioCard = props => {
  const { portfolio } = props;
  const { title, source_code_url, service_url, image } = portfolio;
  const classes = useStyles();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Card className={classes.root} elevation={3}>
        <CardMedia className={classes.cover} image={image} title={title} />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {title}
            </Typography>
            <Typography variant="subtitle1">ソースコード:</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <a href={source_code_url} target="_blank" rel="noopener noreferrer">
                {source_code_url}
              </a>
            </Typography>
            <Typography variant="subtitle1">デプロイ先:</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <a href={service_url} target="_blank" rel="noopener noreferrer">
                {service_url}
              </a>
            </Typography>
          </CardContent>
        </div>
      </Card>
    </div>
  );
};

export default PortfolioCard;

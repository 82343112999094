import React from "react";
import { Link as LinkRouter } from "react-router-dom";

export const Link = ({ children, to, color = "black", width = "100%" }) => {
  return (
    <LinkRouter to={to} style={{ textDecoration: "none", color, width }}>
      {children}
    </LinkRouter>
  );
};

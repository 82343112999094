import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import storage from 'store2';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { signInUser, resetUserPassword } from '../../redux-token-auth';
import { googleAnalytics } from '../../utils/pageGA';
import PositionSnackBar from '../../components/common/PositionSnackBar'

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    open: false
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    color: 'red',
  },
  forgetPasswordLink: {
    marginTop: '5%',
    cursor: 'pointer'
  },
  fullWidth: {
    width: '100%'
  }
});

class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    has_error: false,
    displayResetForm: false,
    message: ''
  };
  onChangeEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  submitForm = (e) => {
    e.preventDefault();
    const { signInUser } = this.props;
    const { email, password } = this.state;
    signInUser({ email, password })
      .then(() => {
        this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({ has_error: true });
      });
  };
  submitResetForm = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const res = await resetUserPassword(email)
    this.setState({ message: res.message, open: true, has_error: !res.success })
    // 有効じゃないときの処理をする
    // successだったらlocalstrageにemailセット
    res.success ? storage.local.set('uid', email) : this.setState({ has_error: true });
  }
  componentDidMount() {
    googleAnalytics({
      pathname: this.props.history.location.pathname,
    });
  }
  closeSnackBar = () => {
    this.setState({ open: false })
  }
  render() {
    const { classes } = this.props;
    const { displayResetForm, has_error, open, email, message } = this.state;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            DIVE INTO WORK
          </Typography>
          <PositionSnackBar
            vertical="top"
            horizontal="center"
            message={message}
            closeSnackBar={ this.closeSnackBar }
            open={open}
          />
          {has_error && <p className={classes.error}>入力に誤りがあります。</p>}
          {
            displayResetForm ? (
            <>
              <ValidatorForm
                className={classes.form}
                onSubmit={this.submitResetForm}
              >
                <FormControl margin='normal' fullWidth>
                  <TextValidator
                    label='メールアドレス'
                    id='email'
                    name='email'
                    autoComplete='email'
                    type="email"
                    autoFocus
                    onChange={this.onChangeEmail}
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['メールアドレスは必須です', 'このメールアドレスは無効です']}
                    className={classes.fullWidth}
                  />
                </FormControl>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                >
                  パスワード再設定のメールを送信する
                </Button>
              </ValidatorForm>
              <a onClick={(e) =>
                e.preventDefault
                (this.setState({ displayResetForm: false }))
              }
                className={classes.forgetPasswordLink}
              >
                ログイン画面に戻る
              </a>
            </>
            )
            : (
              <>
              <form className={classes.form}>
                <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='email'>メールアドレス</InputLabel>
                  <Input
                    id='email'
                    name='email'
                    autoComplete='email'
                    autoFocus
                    onChange={this.onChangeEmail}
                  />
                </FormControl>
                <FormControl margin='normal' required fullWidth>
                  <InputLabel htmlFor='password'>パスワード</InputLabel>
                  <Input
                    name='password'
                    type='password'
                    id='password'
                    autoComplete='current-password'
                    onChange={this.onChangePassword}
                  />
                </FormControl>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={this.submitForm}
                >
                  ログイン
                </Button>
              </form>
                <a onClick={(e) =>
                  e.preventDefault
                  (this.setState({ displayResetForm: true }))
                }
                  className={classes.forgetPasswordLink}
                >
                  パスワードお忘れの場合
                </a>
              </>
            )
          }
        </Paper>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, { signInUser })
)(SignIn);

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { makeStyles, Grid } from '@material-ui/core';

import { getInbox } from '../../actions/chat';

import Layout from '../../components/Layout';
import SideDrawer from '../../components/common/Drawer';
import ChatForm from './ChatForm';
import MessageBoxList from './MessageBoxList';

const useStyles = makeStyles(theme => ({
  containerGrid: {
    justifyContent: 'center',
    paddingLeft: 248,
    flexDirection: 'row',
  },
  media: {
    height: '100px'
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px'
  },
  avatar: {
    margin: 10
  },
  paper: {
    padding: 10
  },
  list: {
    padding: 10
  },
  chatForm: {
    width: '55%',
    boxShadow: '0 20px 40px -14px rgba(0,0,0,0.25)',
    height: 696,
  },
  gridItem: {
    marginLeft: '4%'
  },
  chatTextArea: {
    width: '100%',
    height: 80,
    background: 'rgb(237,239,241)',
    outline: 'none',
    fontSize: 14,
    padding: 12,
    borderRadius: '0 0 20px 20px',
    border: 'none',
    resize: 'none',
    boxShadow: '0 20px 40px -14px rgba(0,0,0,0.25)'
  },
  chatSendButton: {
    padding: 0,
    border: 0,
    background: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    bottom: 10
  },
  chatFileButton: {
    padding: 0,
    border: 0,
    background: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'absolute',
    right: 50,
    bottom: 10
  }
}));

const MessageBox = props => {
  const { inboxes, search_inboxes, searchHandler } = props;
  const [checkedList, changeSelect] = useState(inboxes[0]);
  const [firstAccess, doneAccess] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const showInboxes = search_inboxes.length > 0 ? search_inboxes : inboxes;
  const roomNum =
    showInboxes.length > 0 ? showInboxes[0].chat_student.student_id : undefined;
  
  const roomStudentPath = showInboxes.length > 0 ? showInboxes[0].chat_student.affiliation_id : undefined;

  const [showRoomNum, setRoomNum] = useState(roomNum);
  const [detailLink, setLinkPath] = useState(roomStudentPath)

  const clickHandle = student_id => {
    const findUser = student_id => user => user.user_id === student_id;
    const selectedUser = inboxes.find(findUser(student_id));
    changeSelect(selectedUser);
    setRoomNum(student_id);
    setLinkPath(selectedUser.chat_student.affiliation_id)
  };

  useEffect(() => {
    doneAccess(true);
    const { studentId } = queryString.parse(window.location.search);
    // topページからアクセスがあった場合に選択した人にカーするが当たる
    if (firstAccess && studentId) {
      const findUser = (studentId => user => user.user_id === studentId)(
        +studentId
      );
      changeSelect(inboxes.find(findUser));
      setRoomNum(studentId);
    }

    const pooling = setInterval(() => {
      dispatch(getInbox()).then(() => {});
    }, 100000);

    // この即時関数はコンポーネントの描画が消える時に実行される
    // 今回の場合は10分おきに実行される関数がclearIntervalされる
    return () => clearInterval(pooling);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firstAccess]);

  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
          <SideDrawer />
          { roomNum === undefined ?
            <Redirect
              to={{
                pathname: 'Offering_students',
                state: { showIntroduction: true }
              }}
            />

          :

            <Grid container direction="row" justify="center" alignItems="stretch" >
              <MessageBoxList
                inboxes={inboxes}
                showInboxes={showInboxes}
                searchHandler={searchHandler}
                clickHandler={clickHandle}
                checkedList={checkedList}
              >
              </MessageBoxList>
              <div className={classes.chatForm}>
                <ChatForm
                  chatRoomId={showRoomNum}
                  linkPath={detailLink}
                  classes={classes}
                />
              </div>
            </Grid>

          }
      </Grid>
    </Layout>
  );
};
export default MessageBox;

// import { composeWithDevTools } from "redux-devtools-extension";
// import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

import reducer from './reducers';
import { setupAxios } from './utils/HttpClient';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// redux devtoolの非表示設定
// https://github.com/reduxjs/redux-toolkit/blob/master/docs/api/configureStore.md

const history = createBrowserHistory();
const initialState = {};
const store = configureStore({
  reducer: reducer(history),
  middleware: [...getDefaultMiddleware(), thunk, routerMiddleware(history)],
  devTools: process.env.NODE_ENV !== 'production',
  initialState,
});

setupAxios.setupInterceptors(store, history);

export default store;

import Dashboard from './container/DashBoard/index';
import Signin from './container/Signin/index';
import Signup from './container/Signup/index';
import PasswordReset from './container/PasswordReset/index';
import RecruitList from './container/Recruit/list';
import RecruitShow from './container/Recruit/show';
import RecruitEdit from './container/Recruit/edit';
import RecruitNew from './container/Recruit/new';
import OfferingStudents from './container/OfferingStudents';
import StudentDetail from './container/StudentDetail';
import MessageInbox from './container/Message/inbox';
import Company from './container/Company/Show';
import CompanyEdit from './container/Company/Edit';
import BlogTest from './container/Blog/BlogTest';

import ErrorContainer from './container/Error';
export const routes = [
  {
    path: '/',
    exact: true,
    main: () => Dashboard,
  },
  {
    path: '/offering_students',
    main: () => OfferingStudents,
  },
  {
    path: '/student_detail/:id',
    main: () => StudentDetail,
  },
  {
    path: '/signin',
    exact: true,
    main: () => Signin,
  },
  {
    path: '/signup',
    main: () => Signup,
  },
  {
    path: '/new_password',
    main: () => PasswordReset,
  },
  {
    path: '/recruit',
    exact: true,
    main: () => RecruitList,
  },
  {
    path: '/recruit_new',
    exact: true,
    main: () => RecruitNew,
  },
  {
    path: '/recruit/:id',
    main: () => RecruitShow,
    exact: true,
  },
  {
    path: '/recruit/:id/edit',
    main: () => RecruitEdit,
  },
  {
    path: '/inbox',
    main: () => MessageInbox,
  },
  {
    path: '/blogs',
    main: () => BlogTest,
    exact: true,
  },
  {
    path: '/company',
    main: () => Company,
    exact: true,
  },
  {
    path: '/company/edit',
    main: () => CompanyEdit,
    exact: true,
  },
  {
    main: () => ErrorContainer,
    exact: true,
  },
];

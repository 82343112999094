import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    backgroundColor: '#EDEFF1',
    position: 'absolute',
    zIndex: 9,
    borderRadius: 0
  },
  input: {
    marginLeft: 8,
    flex: 1,
    fontSize: '0.8rem'
  },
  iconButton: {
    padding: 10,
    backgroundColor: '#00B0FF'
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
}));

function StudentSearchInputField(props) {
  const { searchHandler, students } = props;
  const updateQuery = query => {
    searchHandler(query.target.value, students);
  };
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={1}>
      <InputBase
        className={classes.input}
        placeholder="受講生を名前で検索ができます"
        onChange={updateQuery}
      />
      <IconButton className={classes.iconButton} aria-label="Search">
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} />
    </Paper>
  );
}

export default StudentSearchInputField;

import React from 'react';

import { makeStyles, Drawer, List } from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import SearchIcon from '@material-ui/icons/Search';

import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import EmailIcon from '@material-ui/icons/MailOutlineOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import ListLinkItem from './ListLinkItem';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    boxShadow: '3px 0 4px 0 rgba(0, 0, 0, 0.2)'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  toolbar: theme.mixins.toolbar
}));

const managementItems = {
  title: '管理',
  items: [{ name: 'ダッシュボード', icon: <DashboardIcon />, to: '/' }]
};

const recruiterItems = {
  title: '候補者',
  items: [
    {
      name: '求職者検索',
      icon: <SearchIcon />,
      to: '/offering_students'
    },
    { name: 'メッセージ', icon: <EmailIcon />, to: '/inbox' }
  ]
};

const accuntItems = {
  title: 'アカウント情報',
  items: [
    {
      name: '会社・社員情報',
      icon: <SettingsApplicationsIcon />,
      to: '/company'
    }
  ]
};

export default function ClippedDrawer({ children, isShowManage }) {
  const classes = useStyles();

  return (
    <Drawer classes={{ paper: classes.drawer }} variant="permanent">
      <div className={classes.toolbar}/>
      {!isShowManage && (
        <>
          <List style={{ padding: 0, }}>
            {managementItems.items.map((obj, index) => (
              <ListLinkItem style={{ padding: 0, }}
                icon={obj.icon}
                primary={obj.name}
                to={obj.to}
                key={index}
              />
            ))}
          </List>
          <List style={{ padding: 0, }}>
            {recruiterItems.items.map((obj, index) => (
              <ListLinkItem style={{ padding: 0, }}
                icon={obj.icon}
                primary={obj.name}
                to={obj.to}
                key={index}
              />
            ))}
          </List>
          <List style={{ padding: 0, }}>
            {accuntItems.items.map((obj, index) => (
              <ListLinkItem style={{ padding: 0, }}
                icon={obj.icon}
                primary={obj.name}
                to={obj.to}
                key={index}
              />
            ))}
          </List>

          <a href="https://forms.gle/YBjV3iCnbBhKRwN97" target="_blank" rel="noopener noreferrer"
             style={{ textDecoration: 'none', margin: '2px 0' }}
          >
            <li style={{ listStyle: 'none', display: 'flex', alignItems: 'center', paddingLeft: 16, margin: '16px 0' }}>
              <ErrorIcon style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 16 }}/>
              <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>ご意見・ご要望はこちら</span>
            </li>
          </a>
        </>
      )}

      {children}
    </Drawer>
  );
}

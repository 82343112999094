import { useState } from 'react';

export default initialValue => {
  const [formObj, setFormVal] = useState(initialValue);
  return {
    formObj,
    onChange: ({ target: { name, value } }) => {
      setFormVal({
        ...formObj,
        [name]: value
      });
    }
  };
};

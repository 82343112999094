import API_CLIENT from '../utils/HttpClient';

export default {
  getAll() {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/job_offers'
    });
  },
  show(Id) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/job_offers/${Id}`
    });
  },
  edit(Id) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/job_offers/${Id}/edit`
    });
  },
  create(data) {
    return API_CLIENT({
      method: 'POST',
      url: '/api/v2/recruit/job_offers',
      data: setForm(data)
    });
  },
  update(data) {
    return API_CLIENT({
      method: 'PUT',
      url: `/api/v2/recruit/job_offers/${data.id}`,
      data: setForm(data)
    });
  },
  delete(Id) {
    return API_CLIENT({
      method: 'DELETE',
      url: `/api/v2/recruit/job_offers/${Id}`
    });
  }
};

function setForm(data) {
  const requiredPropList = [
    'title',
    'content',
    'publish',
    'estimated_annual_income',
    'ideal_candidate',
    'contract_type',
    'hiring_process',
    'workplace_address'
  ];
  const formData = new FormData();
  Object.keys(data).forEach(
    k =>
      (data[k] || requiredPropList.includes(k)) &&
      formData.append(`job_offer[${k}]`, data[k])
  );
  return formData;
}

import React from 'react';
import { Avatar } from '@material-ui/core';

export default function SnsIcon({ snsHandler, classes }) {
  const snsArray = snsHandler();

  return (
    <span
      style={{
        display: 'inline-flex',
        marginLeft: 24,
        position: 'relative',
        top: '7px',
      }}
    >
      {snsArray.map((sns, index) =>
        sns.path ? (
          <a href={sns.path} rel='noopener noreferrer' target='_blank'>
            <Avatar
              variant='rounded'
              className={classes.small}
              src={sns.imageUrl}
            />
          </a>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a style={{ opacity: '0.4' }} key={index} disabled>
            <Avatar
              variant='rounded'
              className={classes.small}
              src={sns.imageUrl}
            />
          </a>
        )
      )}
    </span>
  );
}

import employeeApi from '../api/user';
import deviseInvitation from '../api/deviseInvitation';

import { undefinedError } from './error';
import { ADD_EMPLOYEE } from './company';
export const USERS = 'USERS';
export const EDIT_USERS = 'EDIT_USERS';
export const UNDEFINED_ERROR = 'UNDEFINED_ERROR';
export const DELETE_USER = 'DELETE_USER';

export function fetchUsers() {
  return async dispatch => {
    try {
      const res = await employeeApi.getAll();
      dispatch(receiveUsers(res.data));
    } catch (err) {
      dispatch(undefinedError());
    }
  };
}

export function createUser(data, company_id) {
  return async dispatch => {
    const res = await deviseInvitation.addInvitable(data, company_id);
    dispatch(invitableUser(res.data));
  };
}

export function deleteEmployee(Id) {
  return async dispatch => {
    await employeeApi.deleteEmployee(Id);
    dispatch(deleteUser(Id));
  };
}

export function updateEmployee({ data }) {
  return async dispatch => {
    await employeeApi.updateEmployee({ data });
  };
}

export function editEmployee(Id) {
  return dispatch => {
    dispatch(editUsers(Id));
  };
}

function editUsers(Id) {
  return {
    type: EDIT_USERS,
    Id
  };
}
function receiveUsers(users) {
  return {
    type: USERS,
    users
  };
}

function deleteUser(id) {
  return {
    type: DELETE_USER,
    id
  };
}

function invitableUser(res) {
  return {
    type: ADD_EMPLOYEE,
    res
  };
}

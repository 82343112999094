import studentApi from '../api/student';

export const STUDENTS = 'STUDENTS';
export const STUDENT = 'STUDENT';
export const GRADUATE_STUDENTS = 'GRADUATE_STUDENTS';
export const NEW_REGISTERED_STUDENTS = 'NEW_REGISTERED_STUDENTS';
export const SEARCH_STUDENTS = 'SEARCH_STUDENTS';
export const UPDATE_FAVORITE = 'UPDATE_FAVORITE';
export const UPDATE_FAVORITE_STUDENT = 'UPDATE_FAVORITE_STUDENT';

export function fetchStudents([page, row_count, searchKey] = [0, 10, {}]) {
  let optimizeSearchKey;
  optimizeSearchKey =
    searchKey.address === '選択なし'
      ? { ...searchKey, address: '' }
      : searchKey;
  optimizeSearchKey.graduateDate =
    optimizeSearchKey.graduateDate === '' ||
    optimizeSearchKey.graduateDate === 'null'
      ? null
      : optimizeSearchKey.graduateDate;
  optimizeSearchKey =
    typeof optimizeSearchKey.graduateDate === 'string'
      ? {
          ...optimizeSearchKey,
          graduateDate: new Date(optimizeSearchKey.graduateDate),
        }
      : optimizeSearchKey;

  return async (dispatch) => {
    const res = await studentApi.getStudents(
      page,
      row_count,
      optimizeSearchKey
    );
    dispatch(receiveStudents(res.data.data));
  };
}

export function fetchExpectedGraduatesStudents(
  [page, row_count, searchKey] = [0, 10, {}]
) {
  return async (dispatch) => {
    const res = await studentApi.getStudents(page, row_count, searchKey);

    dispatch(receiveExpectedGraduateStudents(res.data.data));
    dispatch(
      receiveNewRegisterdStudents(res.data.meta.new_registerd_students.data)
    );
  };
}

export function fetchStudent(Id) {
  return async (dispatch) => {
    try {
      const res = await studentApi.getStudent(Id);
      dispatch(receiveStudent(res.data.data));
    } catch (err) {
      console.log(err);
      return err;
    }
  };
}

export function createFavorite({ studentId, callLocation }) {
  return async (dispatch) => {
    const res = await studentApi.createFavorite({ studentId });
    callLocation === 'show'
      ? dispatch(favoriteStudentFromShow(res.data.favoriteId, true))
      : dispatch(favoriteStudent(studentId, true));
  };
}

export function deleteFavorite({ studentId, favoriteId }) {
  return async (dispatch) => {
    await studentApi.deleteFavorite({ studentId, favoriteId });
    dispatch(favoriteStudent(studentId, false));
  };
}
// NOTE: ボタンクリック時にURLを取得するために利用, urlは別タブで開く時のみ利用するためstoreの更新はしない
export function fetchSelfIntroductionSlide({ studentId }) {
  return async (dispatch) => {
    const res = await studentApi.fetchSelfIntroductionSlide({ studentId });
    return res;
  };
}
// NOTE: ボタンクリック時にURLを取得するために利用, urlは別タブで開く時のみ利用するためstoreの更新はしない
export function fetchPersonalHistory({ studentId }) {
  return async (dispatch) => {
    const res = await studentApi.fetchPersonalHistory({ studentId });
    return res;
  };
}
// NOTE: ボタンクリック時にURLを取得するために利用, urlは別タブで開く時のみ利用するためstoreの更新はしない
export function fetchJobHistory({ studentId }) {
  return async (dispatch) => {
    const res = await studentApi.fetchJobHistory({ studentId });
    return res;
  };
}

function receiveStudents(students) {
  return {
    type: STUDENTS,
    students,
  };
}

function receiveExpectedGraduateStudents(students) {
  return {
    type: GRADUATE_STUDENTS,
    students,
  };
}

function receiveNewRegisterdStudents(new_registerd_students) {
  return {
    type: NEW_REGISTERED_STUDENTS,
    new_registerd_students,
  };
}

function receiveStudent(student) {
  return {
    type: STUDENT,
    student,
  };
}

function favoriteStudent(studentId, isBookMark) {
  return {
    type: UPDATE_FAVORITE,
    studentId: studentId,
    isBookMark: isBookMark,
  };
}

function favoriteStudentFromShow(favoriteId, isBookMark) {
  return {
    type: UPDATE_FAVORITE_STUDENT,
    favoriteId: favoriteId,
    isBookMark: isBookMark,
  };
}

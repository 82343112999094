import React from 'react';
import { Grid, Paper, makeStyles, Typography, Box } from '@material-ui/core';

import Layout from '../components/Layout';
import SideDrawer from '../components/common/Drawer';
import { Link } from '../components/common/Link';

const useStyles = makeStyles(theme => ({
  containerGrid: {
    justifyContent: 'center'
  }
}));

const RecruitNew = props => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid>
          <SideDrawer />
          <Paper style={{ padding: 20 }}>
            <Typography component="div">
              <Box textAlign="center" m={1}>
                このページはご利用いただけません。リンクに問題があるかページが削除された可能性があります。
              </Box>
              <Box textAlign="center" m={1}>
                <Link to="/" color="blue">
                  トップに戻る
                </Link>
              </Box>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RecruitNew;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Card,
  CardMedia,
  CardContent,
  Button,
  makeStyles,
  FormLabel,
} from '@material-ui/core';

import Layout from '../../components/Layout';
import SideDrawer from '../../components/common/Drawer';
import CollspanTextField from '../../components/JobOffer/CollspanTextField';

import { createJobOffer } from '../../actions/jobOffers';
import { fetchCompany } from '../../actions/company';
import initialValue from '../../state/ui/useInputState';
import { googleAnalytics } from '../../utils/pageGA';

const useStyles = makeStyles((theme) => ({
  containerGrid: {
    paddingLeft: 260,
  },
  media: {
    height: 222,
    width: 1111,
  },
  row: {
    flexDirection: 'row',
  },
  collspanGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fixedButton: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
  },
  formControl: {
    margin: '15px 0',
  },
  button: {
    height: 56,
    width: 216,
    borderRadius: 25,
    fontWeight: 600,
  },
  radioButtonFieldContainer: {
    paddingTop: '8px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'center',
  },
  companyEasyProfileWrapper: {
    width: '22%',
    paddingLeft: 32,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  companyIntroduction: {
    width: 182,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: '16px 0 56px 0',
    letterSpacing: 1.1,
  },
  required: {
    color: '#f50057',
  },
}));

const RecruitNew = (props) => {
  const { history } = props;
  const classes = useStyles();

  const { formObj, onChange } = initialValue({
    category: 'other',
    publish: 'false',
    joining_date: null,
    title: '',
    content: '',
  });

  const [hideObj, changeHide] = useState({
    worthiness: false,
  });

  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const jobOffer = useSelector((state) => state.job_offer);
  const headerImage = company.header_image_url && company.header_image_url;
  const profileImage = company.profile_image && company.profile_image.url;

  const createHandler = async () => {
    await dispatch(createJobOffer(formObj));
    window.scrollTo({ top: 0 });
  };

  const handleHideClick = (prop) => (event) => {
    changeHide({ ...hideObj, [prop]: !hideObj[prop] });
  };

  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    if (jobOffer.created) return history.push(`/recruit/${jobOffer.id}`);
    dispatch(fetchCompany());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.history, jobOffer.created]);

  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={7}>
          <SideDrawer />
          <Card style={{ width: 1111 }}>
            <CardMedia className={classes.media} image={headerImage || ''} />
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '78%' }}>
                  <h1>新規求人作成</h1>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}
                    fullWidth
                  >
                    <FormLabel component='legend' focused={false}>
                      公開設定
                    </FormLabel>
                    <RadioGroup
                      className={
                        (classes.row, classes.radioButtonFieldContainer)
                      }
                      defaultValue='false'
                      aria-label='publish'
                      name='publish'
                      value={formObj.publish}
                      onChange={onChange}
                      row
                    >
                      <FormControlLabel
                        value='true'
                        control={<Radio />}
                        label='公開'
                      />
                      <FormControlLabel
                        value='false'
                        control={<Radio />}
                        label='非公開'
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    component='fieldset'
                    className={classes.formControl}
                    fullWidth
                  >
                    <FormLabel component='legend' focused={false}>
                      カテゴリ
                    </FormLabel>
                    <RadioGroup
                      className={
                        (classes.row, classes.radioButtonFieldContainer)
                      }
                      aria-label='category'
                      name='category'
                      value={formObj.category}
                      onChange={onChange}
                      row
                    >
                      <FormControlLabel
                        value='web'
                        control={<Radio />}
                        label='Webエンジニアコース向け'
                      />
                      <FormControlLabel
                        value='ml'
                        control={<Radio />}
                        label='機械学習エンジニアコース向け'
                      />
                      <FormControlLabel
                        value='other'
                        control={<Radio />}
                        label='指定なし'
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id='recruit-form-title'
                      name='title'
                      value={formObj.title}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.title
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.title
                      }
                      onChange={onChange}
                      variant='outlined'
                      label='タイトル (必須)'
                      placeholder={`（例）〇〇業界を変えたいWebアプリ開発エンジニア募集！`}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                    />
                  </FormControl>
                  <h2>仕事について</h2>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name='content'
                      value={formObj.content}
                      onChange={onChange}
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.content
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.content
                      }
                      required
                      variant='outlined'
                      label='内容 (必須)'
                      multiline
                      rows='20'
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                    />
                  </FormControl>
                  <CollspanTextField
                    title={'開発環境'}
                    onClick={handleHideClick('development_environment')}
                    classes={classes}
                    name={'development_environment'}
                    boolFlg={hideObj['development_environment']}
                    onChange={onChange}
                    inputValue={formObj.development_environment}
                    rows='2'
                    placeholder={`(例）エディタ（VSCODE）、インフラ（AWS）、アジャイル開発、5名のチーム体制など`}
                  />
                  <CollspanTextField
                    title={'サポート体制'}
                    onClick={handleHideClick('support_system')}
                    classes={classes}
                    name={'support_system'}
                    boolFlg={hideObj['support_system']}
                    onChange={onChange}
                    inputValue={formObj.support_system}
                    rows='2'
                    placeholder={`(例）なし、OJT、1ヶ月間程度研修など`}
                  />
                  <CollspanTextField
                    title={'1日の流れ'}
                    onClick={handleHideClick('day_flow')}
                    classes={classes}
                    name={'day_flow'}
                    boolFlg={hideObj['day_flow']}
                    onChange={onChange}
                    inputValue={formObj.day_flow}
                    rows='2'
                  />
                  <CollspanTextField
                    title={'働き方'}
                    onClick={handleHideClick('work_style')}
                    classes={classes}
                    name={'work_style'}
                    boolFlg={hideObj['work_style']}
                    onChange={onChange}
                    inputValue={formObj.work_style}
                    rows='2'
                    placeholder={`(例）リモートワーク可能、出社週1回など`}
                  />
                  <h2>募集要項</h2>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id='recruit-form-title'
                      name='job_type'
                      value={formObj.job_type}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.job_type
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.job_type
                      }
                      onChange={onChange}
                      variant='outlined'
                      label='募集職種 (必須)'
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      id='recruit-form-title'
                      name='estimated_annual_income'
                      value={formObj.estimated_annual_income}
                      onChange={onChange}
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.estimated_annual_income
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.estimated_annual_income
                      }
                      required
                      placeholder={`（例）400万〜500万、スキル経験による等を想定する`}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                      variant='outlined'
                      label='想定年収 (必須)'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name='ideal_candidate'
                      value={formObj.ideal_candidate}
                      onChange={onChange}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.ideal_candidate
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.ideal_candidate
                      }
                      variant='outlined'
                      label='求める人物像 (必須)'
                      placeholder={`①ハードスキル（プログラミング技術）\n  - ハードスキル必須：（例）DIVEINTOCODE卒業レベル、エンジニア実務歴〜年など\n  - ハードスキル任意：（例）Kotlinなどモバイルアプリに興味がある方など\n\n②ソフトスキル（人柄・価値観）\n（例）：当事者意識・主体性をもって課題解決に取り組める方、休日でも積極的に勉強会に参加するなど向上心がある方\n\n③経歴/学歴\n（例）：SIerなどIT業界にいた経験`}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                      multiline
                      rows='10'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name='contract_type'
                      value={formObj.contract_type}
                      onChange={onChange}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.contract_type
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.contract_type
                      }
                      variant='outlined'
                      label='雇用形態 (必須)'
                      placeholder={`（例）正社員、インターン`}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                      multiline
                      rows='1'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name='hiring_process'
                      value={formObj.hiring_process}
                      onChange={onChange}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.hiring_process
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.hiring_process
                      }
                      variant='outlined'
                      label='選考フロー (必須)'
                      placeholder={`（例）書類選考→一次面接→Webテスト→最終面接`}
                      multiline
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                      rows='8'
                    />
                  </FormControl>
                  <FormControl fullWidth className={classes.formControl}>
                    <TextField
                      name='workplace_address'
                      value={formObj.workplace_address}
                      onChange={onChange}
                      required
                      error={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.workplace_address
                      }
                      helperText={
                        jobOffer.errors &&
                        jobOffer.errors.error &&
                        jobOffer.errors.error.workplace_address
                      }
                      variant='outlined'
                      label='勤務地 (必須)'
                      placeholder={`（例）東京渋谷（本社）`}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.required,
                      }}
                    />
                  </FormControl>
                  <CollspanTextField
                    title={'入社時期'}
                    onClick={handleHideClick('joining_date')}
                    classes={classes}
                    name={'joining_date'}
                    boolFlg={hideObj['joining_date']}
                    onChange={onChange}
                    inputValue={formObj.joining_date}
                    placeholder={`（例）即日～、4月からなど`}
                  />
                  <CollspanTextField
                    title={'採用予定人数'}
                    onClick={handleHideClick('recruitment_numbers')}
                    classes={classes}
                    fieldType='number'
                    name={'recruitment_numbers'}
                    boolFlg={hideObj['recruitment_numbers']}
                    onChange={onChange}
                    inputValue={formObj.recruitment_numbers}
                  />
                  <CollspanTextField
                    title={'勤務時間'}
                    onClick={handleHideClick('working_hours')}
                    classes={classes}
                    name={'working_hours'}
                    fieldType='number'
                    boolFlg={hideObj['working_hours']}
                    onChange={onChange}
                    inputValue={formObj.working_hours}
                    placeholder={`（例）10:00〜19:00（実働時間8時間、休憩1時間）`}
                  />
                  <CollspanTextField
                    title={'残業時間'}
                    onClick={handleHideClick('overtime_schedule')}
                    classes={classes}
                    name={'overtime_schedule'}
                    fieldType='number'
                    boolFlg={hideObj['overtime_schedule']}
                    onChange={onChange}
                    inputValue={formObj.overtime_schedule}
                    placeholder={`（例）平均残業時間は月20時間程度`}
                  />
                  <CollspanTextField
                    title={'選考詳細'}
                    onClick={handleHideClick('hiring_detail')}
                    classes={classes}
                    name={'hiring_detail'}
                    boolFlg={hideObj['hiring_detail']}
                    onChange={onChange}
                    inputValue={formObj.hiring_detail}
                    placeholder={`（例）選考は通常2週間程度、面接はそれぞれ1時間程度を想定しています。`}
                    rows='2'
                  />
                  {/* <CollspanTextField
                    title={'特記事項'}
                    onClick={handleHideClick('special_notes')}
                    classes={classes}
                    name={'special_notes'}
                    boolFlg={hideObj['special_notes']}
                    onChange={onChange}
                    inputValue={formObj.special_notes}
                    placeholder={`DICエージェント限定情報（この項目は求職者には公開されません）`}
                    rows="2"
                  /> */}
                  <h2>仕事環境</h2>
                  <CollspanTextField
                    title={'試用期間'}
                    onClick={handleHideClick('trial_period')}
                    classes={classes}
                    name={'trial_period'}
                    boolFlg={hideObj['trial_period']}
                    onChange={onChange}
                    inputValue={formObj.trial_period}
                    rows='2'
                    placeholder={`（例）あり。試用期間3ヶ月（ただし期間中の待遇差はありません）`}
                  />
                  <CollspanTextField
                    title={'待遇（手当てなど）'}
                    onClick={handleHideClick('allowance')}
                    classes={classes}
                    name={'allowance'}
                    boolFlg={hideObj['allowance']}
                    onChange={onChange}
                    inputValue={formObj.allowance}
                    rows='2'
                    placeholder={`（例）社会保険完備（雇用・労災・健康・介護・厚生年金）`}
                  />
                  <CollspanTextField
                    title={'年間休日'}
                    onClick={handleHideClick('days_off')}
                    classes={classes}
                    name={'days_off'}
                    fieldType='number'
                    boolFlg={hideObj['days_off']}
                    onChange={onChange}
                    inputValue={formObj.days_off}
                    rows='2'
                    placeholder={`（例）122日`}
                  />
                  <CollspanTextField
                    title={'休日・休暇制度'}
                    onClick={handleHideClick('leave_system')}
                    classes={classes}
                    name={'leave_system'}
                    boolFlg={hideObj['leave_system']}
                    onChange={onChange}
                    inputValue={formObj.leave_system}
                    rows='2'
                    placeholder={`（例）結婚休暇、忌引休暇、産前産後休暇、育児・介護看護休暇`}
                  />
                  <CollspanTextField
                    title={'福利厚生'}
                    onClick={handleHideClick('employee_welfare')}
                    classes={classes}
                    name={'employee_welfare'}
                    boolFlg={hideObj['employee_welfare']}
                    onChange={onChange}
                    inputValue={formObj.employee_welfare}
                    rows='2'
                    placeholder={`（例）持株会、積立貯蓄、財形制度、退職金制度`}
                  />
                  <div className={classes.buttonArea} style={{ marginTop: 24 }}>
                    <Button
                      variant='outlined'
                      color='primary'
                      onClick={createHandler}
                      className={classes.button}
                    >
                      新規作成
                    </Button>
                  </div>
                </div>
                <div className={classes.companyEasyProfileWrapper}>
                  <img
                    src={profileImage}
                    style={{
                      width: 182,
                      height: 182,
                      objectFit: 'cover',
                      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 20px 40px -14px',
                    }}
                    alt='Company Logo'
                  />
                  <h4 style={{ width: 188, fontSize: 15, marginBottom: 0 }}>
                    {company.name}
                  </h4>
                  <p style={{ width: 188, fontSize: 10 }}>{company.address}</p>
                  <p className={classes.companyIntroduction}>
                    {company.introduction}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RecruitNew;

import API_CLIENT from "../utils/HttpClient";

export default {
  getAll() {
    return API_CLIENT({
      method: "GET",
      url: "/api/v2/recruit/recruit_items"
    });
  }
};

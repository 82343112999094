import React, { Component } from "react";
import createStyled from 'material-ui-render-props-styles'
import CircularProgress from "@material-ui/core/CircularProgress";

const Styled = createStyled({
  progress: {
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    margin: "auto"
  }
});
function HocIndicator(WithComponent) {
  return class extends Component {
    render() {
      return (
        <div>
          {this.props.indicatorFlg ? (
            <Styled>
              {({ classes }) => (
                <CircularProgress
                  color="secondary"
                  className={classes.progress}
                />
              )}
            </Styled>
          ) : (
            <WithComponent {...this.props} />
          )}
        </div>
      );
    }
  };
}

export default HocIndicator;

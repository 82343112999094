import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/ja';
import moment from 'moment';
import { fetchChatMessages, createChatMessage, setWaitingMessage } from '../../actions/chat';
import {
  ThemeProvider,
  MessageList,
  MessageGroup,
  Message,
  MessageText,
  TitleBar,
} from '@livechat/ui-kit';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Link } from './../common/Link';
import CleanHtml from '../common/CleanHtml';
import CircularIndeterminate from '../common/CircularIndeterminate';
import '../../css/chatMessage.css';

const ChatForm = props => {
  const { chatRoomId, linkPath, classes } = props;
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const room_id = useSelector(state => state.messages.room_id);
  const chat_messages = useSelector(state => state.messages);
  const chat_student = useSelector(state => state.messages.chat_student);
  const MAX_MB = 2;
  const FILE_TYPE = 'application/pdf'

  const changeHandler = e => {
    setMessage(e.target.value);
  };
  const clickHandler = () => {
    dispatch(
      createChatMessage({
        message,
        room_id,
        chatRoomId
      })
    );
  };

  const changeFileHandler = (e) => {
    const file = e.currentTarget.files[0];
    const errorElement = document.querySelector('[data-upload-error]');

    if (file.type.match(FILE_TYPE) === null) {
      setErrorMessage(selectErrorMessage(FILE_TYPE))
      errorElement.style.display = 'block';
      e.currentTarget.value = '';
    } else if (file.size > maxMB()) {
      setErrorMessage(`${MAX_MB}MB以上のPDFは設定できません。`)
      errorElement.style.display = 'block';
      e.currentTarget.value = '';
    } else {
      const reader = new FileReader();
      errorElement.style.display = 'none';
      reader.readAsDataURL(file);
      reader.onload = () => {
        dispatch(setWaitingMessage())
        dispatch(
          createChatMessage({
            file,
            room_id,
            chatRoomId
          })
        );
        document.getElementsByTagName("input")[1].value = null;
      };
    }
  }
  const openUploader = e => {
    e.preventDefault();
    document.querySelector('[data-file-field]').click();
  }
  const selectErrorMessage = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return '不正なファイル形式です。pdfを選択して下さい';
      default:
        return '不正なファイル形式です。';
    }
  }

  const maxMB = () => {
    return MAX_MB * 1024 * 1024;
  }

  const messageTime = createTime => {
    return moment(createTime).calendar();
  };

  useEffect(() => {
    dispatch(fetchChatMessages(chatRoomId));
  }, [dispatch, chatRoomId]);
  return (
    <ThemeProvider>
      <div style={{ maxWidth: '100%', height: 696 }}>
        <Link to={`/student_detail/${linkPath}`}>
          <TitleBar
            title={
              chat_student.name === undefined
                ? 'Now loading'
                : `To: ${chat_student.name}`
            }
            style={{
              backgroundColor: 'rgb(237,239,241)',
              color: 'black',
              height: 48,
              fontSize: 16,
              boxShadow: '0 3px 2px -2px gray'
            }}
          />
        </Link>
        <MessageList active style={{ height: 605 }}>
          <div style={{ maxWidth: '100%' }}>
            {chat_messages.messages.map((chat, index) => (
              <MessageGroup
                avatar={chat.is_student ? chat_student.profile_img : null}
                onlyFirstWithMeta
                isOwn={!chat.is_student}
                key={index}
              >
                {
                  chat.id > 0 ? (
                    <Message
                      authorName={chat.name}
                      date={messageTime(chat.created_at)}
                      isOwn={!chat.is_student}
                      style={
                        chat.is_student ? { maxWidth: '50%', fontSize: '1rem' } : { maxWidth: '100%', fontSize: '1rem' }
                      }
                    >
                      <MessageText
                        style={
                          chat.is_student
                            ? { backgroundColor: '#00B0FF', borderRadius: 5, whiteSpace: 'unset' }
                            : { backgroundColor: 'whitesmoke', borderRadius: 5, whiteSpace: 'unset' , maxWidth: '300px'}
                        }
                        className="chat_message_html"
                      >
                        <CleanHtml dirtyHtml={chat.message}  />
                      </MessageText>
                    </Message>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <CircularIndeterminate />
                    </div>
                  )
                }
              </MessageGroup>
            ))}
          </div>
        </MessageList>
        <form onSubmit={clickHandler}>
          <div style={{position: 'relative'}}>
            <textarea
              className={classes.chatTextArea}
              placeholder="Aa"
              type="text"
              required="required"
              onChange={changeHandler}
            ></textarea>
            <>
              <button className={classes.chatFileButton} onClick={openUploader}>
                <AttachFileIcon />
              </button>
              <input onChange={changeFileHandler} type="file" data-file-field className="display-none"></input>
            </>
            <button className={classes.chatSendButton}>
              <SendIcon />
            </button>
          </div>
          <div className="display-none" data-upload-error>
            <div className="error_container">
              <ReportProblemIcon />
              <span class="error-text">{errorMessage}</span>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default ChatForm;

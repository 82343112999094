import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Typography,
  TextField,
  Paper,
  Button,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  outer: {
    width: "100%",
    height: "100%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.default
  },
  inner: {
    width: "720px",
    minHeight: "420px",
    margin: "0 auto",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  hFirst: {
    textAlign: "center",
    color: theme.palette.common.black,
    marginBottom: "12px"
  }
}))

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
  border-radius: 3px;
  border: 0;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
`

const BlogTest = () => {
  const [exerciseObj, setExercise] = useState({exercises: [], title: ''});
  const handleChange = ({ target: { name, value } }) => {
    setExercise({ [name]: value })
  }
  const { title } = exerciseObj
  const classes = useStyles();
  return (
    <>
      <Typography
        className={classes.hFirst}
        variant='body1'
      >
        Exercises
      </Typography>
      <div className={classes.outer}>
        <Paper className={classes.inner}>
          hello
        </Paper>
      </div>
      <form>
        <TextField
          name='title'
          label='Exercise'
          value={title}
          onChange={handleChange}
          margin='normal'
        />
        <StyledButton onClick={() => alert(123)}>
          push
        </StyledButton>
      </form>
    </>
    )
  }

  export default BlogTest
import React from 'react';
import moment from 'moment';

import {
  makeStyles,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Badge
} from '@material-ui/core';

import { Link } from './../common/Link';
import { washedHTML } from '../../utils/CleanHtml';

const messageTime = createTime => {
  return moment(createTime).calendar();
};

const hasMessages = messages => {
  return messages.length > 0;
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  cover: {
    width: '18%'
  },
  name: {
    marginBottom: '10px'
  },
  time: {
    marginLeft: '15px'
  },
  message: {
    fontSize: '0.9rem'
  }
}));

const MessageBoxItem = ({ inbox, clickHandler, checkedList }) => {
  const {
    name,
    profile_img,
    student_id,
    affiliation_id
  } = inbox.chat_student;
  const classes = useStyles();
  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={
          checkedList && inbox.user_id === checkedList.user_id
            ? { backgroundColor: '#00AFFF', borderRadius: 0 }
            : { backgroundColor: 'white', borderRadius: 0 }
        }
      >
        <Link to={`/student_detail/${affiliation_id}`} width={""}>
          <ListItemAvatar>
            <Badge badgeContent={inbox.unread_count} color="primary">
              <Avatar
                alt={name}
                src={profile_img}
              />
            </Badge>
          </ListItemAvatar>
        </Link>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <ListItemText
            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
            onClick={() => {
              clickHandler(student_id);
            }}
            primary={name}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textSecondary"
                  style={{ fontSize: '12px' }}
                >
                  {hasMessages(inbox.messages)
                    ? messageTime(
                        inbox.messages[inbox.messages.length - 1].created_at
                      )
                    : ''}
                </Typography>
              </React.Fragment>
            }
          />
          <ListItemText
            style={{ cursor: 'pointer' }}
            onClick={() => {
              clickHandler(student_id);
            }}
            secondary={
              <React.Fragment>
                {hasMessages(inbox.messages)
                  ? ' ' +
                  washedHTML(inbox.messages[inbox.messages.length - 1].message.slice(0, 15), () => "")
                  : '最初のメッセージを送りましょう'}{' '}
              </React.Fragment>
            }
          />
        </div>

      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
export default MessageBoxItem;

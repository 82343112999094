export const MODAL = "MODAL";

export function toggleModal(bool) {
  return (dispatch) => {
    dispatch(toggleFlg(bool))
  }
}

function toggleFlg(bool) {
  return {
    type: MODAL,
    bool
  }
}
import React, { useState } from 'react';
import { Grid, Tabs, Tab, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  createFavorite,
  deleteFavorite,
  fetchSelfIntroductionSlide,
  fetchPersonalHistory,
  fetchJobHistory
} from '../../actions/students';
//コンポーネント
import Layout from '../../components/Layout';
import Profile from './Profile';
import PortfolioCard from './PortfolioCard';
import BookMark from '../../components/OfferingStudents/BookMark';
import SideDrawer from '../../components/common/Drawer';
import SnsIcon from '../../components/common/SnsIcon';
import Progress from '../../components/StudentDetail/Progress';
import StudyTime from '../../components/StudentDetail/StudyTime';
import CardContainer from '../../components/common/CardContainer';
import ListItem from './ListItem';
import ModalSelect from '../common/Modal';
import TabPanel from '../common/TabPanel';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '95%',
    marginBottom: '4%',
  },
  flex: {
    display: 'flex',
  },
  containerGrid: {
    justifyContent: 'center',
  },
  media: {
    height: '100px',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  avatar: {
    margin: 10,
  },
  list: {
    padding: 10,
  },
  Card: {
    marginTop: 64,
    marginLeft: 60,
    marginRight: 60,
  },
  Paper: {
    marginTop: 84,
    marginLeft: 100,
    marginRight: 100,
    padding: 20,
  },
  studyTime: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  studyTimeLabel: {
    color: '#ffffff',
    backgroundColor: '#1a237e',
    padding: '5px',
  },
  centerling: {
    textAlign: 'center',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 40,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  commentHeader: {
    padding: '10px',
    position: 'absolute',
    top: -34,
    fontSize: 14,
  },
  comment: {
    backgroundColor: '#f4f5f7',
    borderRadius: '25px',
    padding: '24px 56px',
    position: 'relative',
    margin: '40px 0',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 11px 22px -11px',
  },
  bookMarkPosition: {},
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: '0 4px',
  },
  urlTitle: {
    position: 'relative',
    bottom: '20px',
    left: '64px',
    fontSize: '0.8rem',
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const StudentDetail = ({ student, toggleModal, createChatRoom, fetchStudent }) => {
  const {
    name,
    age,
    development_experience,
    name_kana,
    image,
    self_introduction,
    github_url,
    twitter_url,
    qiita_url,
    wantedly_url,
    youtube_url,
    hatena_url,
    user_id,
    has_chat_rooms,
    desired_salary,
    course,
    questions,
    submissions,
    notes,
    portfolios,
    self_introduction_slide_publish,
    personal_history_publish,
    job_history_publish,
    learning_time_records,
    desired_workplace_location,
    graduation_date,
    affiliation_list
  } = student.attributes.student_info;

  const snsObj = {
    github_url,
    twitter_url,
    qiita_url,
    wantedly_url,
    youtube_url,
    hatena_url,
  };

  const affiliationId = student.id;
  const dispatch = useDispatch();
  const [isBookMark, toggleIsBookMark] = useState({
    isBookMark: student.attributes.student_info.isBookMark,
  });
  // NOTE: 初期値がデフォルトのタブ
  const [value, setValue] = useState('four');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const favoriteHandle = (prop) => () => {
    isBookMark.isBookMark
      ? dispatch(
          deleteFavorite({
            studentId: prop.attributes.student_info.user_id,
            favoriteId: prop.attributes.student_info.favorite_id,
          })
        ).then(() => toggleIsBookMark({ isBookMark: false }))
      : dispatch(
          createFavorite({
            studentId: prop.attributes.student_info.user_id,
            callLocation: 'show',
          })
        ).then(() => toggleIsBookMark({ isBookMark: true }));
  };

  const fetchSelfIntroductionURL = async () => {
    const res = await dispatch(
      fetchSelfIntroductionSlide({ studentId: affiliationId })
    );
    return res;
  };

  const fetchPersonalHistoryURL = async () => {
    const res = await dispatch(
      fetchPersonalHistory({ studentId: affiliationId })
    );
    return res;
  };

  const fetchJobHistoryURL = async () => {
    const res = await dispatch(fetchJobHistory({ studentId: affiliationId }));
    return res;
  };

  const snsList = (snsObj) => () => {
    return Object.keys(snsObj).map((k) => {
      return {
        path: snsObj[k],
        // eslint-disable-next-line
        imageUrl: require(`../../images/logos/logo_${k.substring(
          0,
          k.indexOf('_')
        )}.png`),
      };
    });
  };

  const classes = useStyles();
  const { open } = student;
  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={9} style={{ marginLeft: 240 }}>
          <SideDrawer />
          <Profile
            course={course}
            age={age}
            name={name}
            snsObj={snsObj}
            github_url={github_url}
            name_kana={name_kana}
            image={image}
            open={open}
            toggleHandle={toggleModal}
            development_experience={development_experience}
            snsHandler={snsList}
            desired_salary={desired_salary}
            desired_workplace_location={desired_workplace_location}
            graduation_date={graduation_date}
            self_introduction={self_introduction}
            selfIntroductionSlidePublish={self_introduction_slide_publish}
            personalHistoryPublish={personal_history_publish}
            jobHistoryPublish={job_history_publish}
            selfIntroductionSlideCb={fetchSelfIntroductionURL}
            personalHistoryCb={fetchPersonalHistoryURL}
            jobHistoryCb={fetchJobHistoryURL}
            affiliationList={affiliation_list}
            changeAffiliationCb={fetchStudent}
          >
            <BookMark
              student={isBookMark}
              favoriteHandler={favoriteHandle(student)}
              classes={classes}
            />
          </Profile>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='wrapped label tabs example'
            style={{ marginTop: 32 }}
          >
            <Tab
              value='one'
              label='進捗'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('one')}
            />
            <Tab
              value='two'
              label='学習時間'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('two')}
            />
            <Tab
              value='three'
              label='質問'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('three')}
            />
            <Tab
              value='four'
              label='課題'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('four')}
            />
            <Tab
              value='five'
              label='学習日記'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('five')}
            />
            <Tab
              value='six'
              label='ポートフォリオ'
              style={{ fontSize: 16, fontWeight: 600 }}
              {...a11yProps('six')}
            />
          </Tabs>
          <div style={{ maxWidth: 1046 }}>
            <TabPanel component='div' value={value} index='one'>
              <CardContainer name={course.name}>
                <Progress course={course} classes={classes} />
              </CardContainer>
            </TabPanel>
            <TabPanel value={value} index='two'>
              <CardContainer name='学習時間'>
                <StudyTime
                  classes={classes}
                  studyTime={learning_time_records}
                />
              </CardContainer>
            </TabPanel>
            <TabPanel value={value} index='three'>
              <CardContainer name='投稿質問'>
                {questions.map((question, i) => (
                  <ListItem
                    classes={classes}
                    content={question}
                    name={name}
                    label={'質問タイトル'}
                    key={i}
                  />
                ))}
              </CardContainer>
            </TabPanel>
            <TabPanel value={value} index='four'>
              <CardContainer name='提出課題'>
                {submissions.map((submission, i) => (
                  <ListItem
                    classes={classes}
                    content={submission}
                    name={name}
                    label={'課題タイトル'}
                    key={i}
                  >
                    <span className={classes.urlTitle}>提出URL</span>
                    <SnsIcon
                      snsHandler={snsList({
                        github_url: submission.github_url,
                      })}
                      classes={classes}
                    />
                  </ListItem>
                ))}
              </CardContainer>
            </TabPanel>
            <TabPanel value={value} index='five'>
              <CardContainer name='学習日記'>
                {notes.map((submission, i) => (
                  <ListItem
                    classes={classes}
                    content={submission}
                    name={name}
                    label={'学習日記'}
                    key={i}
                  />
                ))}
              </CardContainer>
            </TabPanel>
            <TabPanel value={value} index='six'>
              <CardContainer name='ポートフォリオ'>
                {portfolios.map((portfolio, i) => (
                  <PortfolioCard portfolio={portfolio} />
                ))}
              </CardContainer>
            </TabPanel>
          </div>

          <ModalSelect
            open={open}
            closeModal={toggleModal}
            submitHandler={createChatRoom}
            user_id={user_id}
            has_chat_rooms={has_chat_rooms}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default StudentDetail;

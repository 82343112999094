import React from 'react';

import {
  FormControl,
  Collapse,
  ListItem,
  ListItemIcon,
  TextField
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

const CollspanTextField = props => {
  const {
    classes,
    title,
    name,
    boolFlg,
    onClick,
    onChange,
    inputValue,
    fieldType,
    rows,
    placeholder
  } = props;
  return (
    <FormControl fullWidth className={classes.formControl}>
      <ListItem button onClick={onClick} className={classes.collspanGroup}>
        <ListItemIcon>{title}</ListItemIcon>
        {boolFlg ? <RemoveIcon /> : <AddIcon />}
      </ListItem>
      <Collapse in={boolFlg} timeout="auto" unmountOnExit>
        <FormControl fullWidth className={classes.formControl}>
          <TextField
            name={name}
            value={inputValue}
            onChange={onChange}
            variant="outlined"
            label={title}
            type={fieldType}
            multiline
            placeholder={placeholder}
            rows={rows}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
      </Collapse>
    </FormControl>
  );
};

export default CollspanTextField;

import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TelegramIcon from '@material-ui/icons/Telegram';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    width: 444,
    fontWeight: 600
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const IconLabelButtons = props => {
  const classes = useStyles();
  const { toggleHandle } = props;
  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        size='small'
        className={classes.button}
        onClick={toggleHandle}
      >
        <TelegramIcon style={{ marginRight: 10 }} />
        メッセージ
      </Button>
    </div>
  );
};

export default IconLabelButtons;

import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import {
  Button,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  TextField
} from '@material-ui/core';

import CollspanTextField from '../../components/JobOffer/CollspanTextField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: 200
  },
  formControl: {
    margin: '15px 0'
  },
  collspanGroup: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    height: 56,
    width: 216,
    borderRadius: 25,
    fontWeight: 600
  },
  companyEasyProfileWrapper: {
    width: '22%',
    paddingLeft: 32,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  companyIntroduction: {
    width: 182,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: '16px 0 56px 0',
    letterSpacing: 1.1
  },
  required: {
    color: '#f50057',
  }
}));

const EditField = props => {
  const classes = useStyles();
  const {
    jobOffer,
    dispatcher,
    inputValueUpdateHandler,
    updateHandler,
    company
  } = props;
  const profileImage = company.profile_image && company.profile_image.url;

  const handleChange = prop => event => {
    const booleanConvert = boolStr => (boolStr === 'true' ? true : false);
    dispatcher(
      inputValueUpdateHandler({
        ...jobOffer,
        [prop]:
          event.target.value === 'true' || event.target.value === 'false'
            ? booleanConvert(event.target.value)
            : event.target.value
      })
    );
  };

  const getJobPublish = publish => {
    if (publish !== undefined) return publish;
    return false;
  };

  const getCategory = category => {
    if (category !== undefined) return category.toLocaleLowerCase();
    return 'other';
  };

  const [hideObj, changeHide] = useState({
    worthiness: false
  });

  const handleHideClick = prop => event => {
    changeHide({ ...hideObj, [prop]: !hideObj[prop] });
  };

  const handleUpdate = () => {
    updateHandler({ ...jobOffer });
  };

  const isEmptyJobOffer = jobOffer => {
    return Object.keys(jobOffer).length === 0;
  };

  const setHideObj = key => {
    if (jobOffer[key]) {
      hideObj[key] = true;
      return true;
    } else {
      return false;
    }
  };

  const boolFlgHandler = key => {
    return hideObj[key] === undefined ? setHideObj(key) : hideObj[key];
  };

  return (
    <>
      {isEmptyJobOffer(jobOffer) ? null : (
        <div style={{ display: 'flex' }}>
          <div style={{ width: '78%' }}>
            <h1>求人編集</h1>
            <FormControl
              component='fieldset'
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component='legend' focused={false}>
                公開設定
              </FormLabel>
              <RadioGroup
                className={(classes.row, classes.radioButtonFieldContainer)}
                defaultValue='false'
                aria-label='publish'
                name='publish'
                value={getJobPublish(jobOffer.publish)}
                onChange={handleChange('publish')}
                row
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label='公開'
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='非公開'
                />
              </RadioGroup>
            </FormControl>
            <FormControl
              component='fieldset'
              className={classes.formControl}
              fullWidth
            >
              <FormLabel component='legend' focused={false}>
                カテゴリ
              </FormLabel>
              <RadioGroup
                className={(classes.row, classes.radioButtonFieldContainer)}
                aria-label='category'
                name='category'
                value={getCategory(jobOffer.category)}
                onChange={handleChange('category')}
                row
              >
                <FormControlLabel
                  value='web'
                  control={<Radio />}
                  label='Webエンジニアコース向け'
                />
                <FormControlLabel
                  value='ml'
                  control={<Radio />}
                  label='機械学習エンジニアコース向け'
                />
                <FormControlLabel
                  value='other'
                  control={<Radio />}
                  label='指定なし'
                />
              </RadioGroup>
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                id='recruit-form-title'
                name='title'
                value={jobOffer.title}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.title
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.title
                }
                onChange={handleChange('title')}
                variant='outlined'
                label='タイトル (必須)'
                placeholder={`（例）〇〇業界を変えたいWebアプリ開発エンジニア募集！`}
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
              />
            </FormControl>
            <h2>仕事について</h2>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='content'
                value={jobOffer.content}
                onChange={handleChange('content')}
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.content
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.content
                }
                required
                variant='outlined'
                label='内容 (必須)'
                multiline
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
                rows='20'
              />
            </FormControl>
            <CollspanTextField
              title={'開発環境'}
              onClick={handleHideClick('development_environment')}
              classes={classes}
              name={'development_environment'}
              boolFlg={boolFlgHandler('development_environment')}
              onChange={handleChange('development_environment')}
              inputValue={jobOffer.development_environment}
              rows='2'
              placeholder={`(例）エディタ（VSCODE）、インフラ（AWS）、アジャイル開発、5名のチーム体制など`}
            />
            <CollspanTextField
              title={'サポート体制'}
              onClick={handleHideClick('support_system')}
              classes={classes}
              name={'support_system'}
              boolFlg={boolFlgHandler('support_system')}
              onChange={handleChange('support_system')}
              inputValue={jobOffer.support_system}
              rows='2'
              placeholder={`(例）なし、OJT、1ヶ月間程度研修など`}
            />
            <CollspanTextField
              title={'1日の流れ'}
              onClick={handleHideClick('day_flow')}
              classes={classes}
              name={'day_flow'}
              boolFlg={boolFlgHandler('day_flow')}
              onChange={handleChange('day_flow')}
              inputValue={jobOffer.day_flow}
              rows='2'
            />
            <CollspanTextField
              title={'働き方'}
              onClick={handleHideClick('work_style')}
              classes={classes}
              name={'work_style'}
              boolFlg={boolFlgHandler('work_style')}
              onChange={handleChange('work_style')}
              inputValue={jobOffer.work_style}
              rows='2'
            />
            <h2>募集要項</h2>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='job_type'
                value={jobOffer.job_type}
                onChange={handleChange('job_type')}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.job_type
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.job_type
                }
                variant='outlined'
                label='募集職種 (必須)'
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='estimated_annual_income'
                value={jobOffer.estimated_annual_income}
                onChange={handleChange('estimated_annual_income')}
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.estimated_annual_income
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.estimated_annual_income
                }
                required
                placeholder={`（例）400万〜500万、スキル経験による等を想定する`}
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
                variant='outlined'
                label='想定年収 (必須)'
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='ideal_candidate'
                value={jobOffer.ideal_candidate}
                onChange={handleChange('ideal_candidate')}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.ideal_candidate
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.ideal_candidate
                }
                variant='outlined'
                label='求める人物像 (必須)'
                placeholder={`①ハードスキル（プログラミング技術）\n  - ハードスキル必須：（例）DIVEINTOCODE卒業レベル、エンジニア実務歴〜年など\n  - ハードスキル任意：（例）Kotlinなどモバイルアプリに興味がある方など\n\n②ソフトスキル（人柄・価値観）\n（例）：当事者意識・主体性をもって課題解決に取り組める方、休日でも積極的に勉強会に参加するなど向上心がある方\n\n③経歴/学歴\n（例）：SIerなどIT業界にいた経験`}
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
                multiline
                rows='10'
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='contract_type'
                value={jobOffer.contract_type}
                onChange={handleChange('contract_type')}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.contract_type
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.contract_type
                }
                variant='outlined'
                label='雇用形態 (必須)'
                placeholder={`（例）正社員、インターン`}
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
                multiline
                rows='1'
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='hiring_process'
                value={jobOffer.hiring_process}
                onChange={handleChange('hiring_process')}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.hiring_process
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.hiring_process
                }
                variant='outlined'
                label='選考フロー (必須)'
                placeholder={`（例）書類選考→一次面接→Webテスト→最終面接`}
                multiline
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
                rows='8'
              />
            </FormControl>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                name='workplace_address'
                value={jobOffer.workplace_address}
                onChange={handleChange('workplace_address')}
                required
                error={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.workplace_address
                }
                helperText={
                  jobOffer.errors &&
                  jobOffer.errors.error &&
                  jobOffer.errors.error.workplace_address
                }
                variant='outlined'
                label='勤務地 (必須)'
                placeholder={`（例）東京渋谷（本社）`}
                InputLabelProps={{
                  shrink: true,
                  className: classes.required,
                }}
              />
            </FormControl>
            <CollspanTextField
              title={'入社時期'}
              onClick={handleHideClick('joining_date')}
              classes={classes}
              name={'joining_date'}
              boolFlg={boolFlgHandler('joining_date')}
              onChange={handleChange('joining_date')}
              inputValue={jobOffer.joining_date}
              placeholder={`（例）即日～、4月からなど`}
            />
            <CollspanTextField
              title={'採用予定人数'}
              onClick={handleHideClick('recruitment_numbers')}
              classes={classes}
              name={'recruitment_numbers'}
              boolFlg={boolFlgHandler('recruitment_numbers')}
              onChange={handleChange('recruitment_numbers')}
              inputValue={jobOffer.recruitment_numbers}
            />
            <CollspanTextField
              title={'勤務時間'}
              onClick={handleHideClick('working_hours')}
              classes={classes}
              name={'working_hours'}
              boolFlg={boolFlgHandler('working_hours')}
              onChange={handleChange('working_hours')}
              inputValue={jobOffer.working_hours}
              placeholder={`（例）10:00〜19:00（実働時間8時間、休憩1時間）`}
            />
            <CollspanTextField
              title={'残業時間'}
              onClick={handleHideClick('overtime_schedule')}
              classes={classes}
              name={'overtime_schedule'}
              boolFlg={boolFlgHandler('overtime_schedule')}
              onChange={handleChange('overtime_schedule')}
              inputValue={jobOffer.overtime_schedule}
              placeholder={`（例）平均残業時間は月20時間程度`}
            />
            <CollspanTextField
              title={'選考詳細'}
              onClick={handleHideClick('hiring_detail')}
              classes={classes}
              name={'hiring_detail'}
              boolFlg={boolFlgHandler('hiring_detail')}
              inputValue={jobOffer.hiring_detail}
              onChange={handleChange('hiring_detail')}
              placeholder={`（例）選考は通常2週間程度、面接はそれぞれ1時間程度を想定しています。`}
              rows='2'
            />
            {/* <CollspanTextField
          title={'特記事項'}
          onClick={handleHideClick('special_notes')}
          classes={classes}
          name={'special_notes'}
          boolFlg={boolFlgHandler('special_notes')}
          inputValue={jobOffer.special_notes}
          onChange={handleChange('special_notes')}
          placeholder={`DICエージェント限定情報（この項目は求職者には公開されません）`}
          rows="2"
        /> */}
            <h2>仕事環境</h2>
            <CollspanTextField
              title={'試用期間'}
              onClick={handleHideClick('trial_period')}
              classes={classes}
              name={'trial_period'}
              boolFlg={boolFlgHandler('trial_period')}
              inputValue={jobOffer.trial_period}
              onChange={handleChange('trial_period')}
              placeholder={`（例）あり。試用期間3ヶ月（ただし期間中の待遇差はありません）`}
              rows='2'
            />
            <CollspanTextField
              title={'待遇（手当てなど）'}
              onClick={handleHideClick('allowance')}
              classes={classes}
              name={'allowance'}
              boolFlg={boolFlgHandler('allowance')}
              inputValue={jobOffer.allowance}
              onChange={handleChange('allowance')}
              placeholder={`（例）社会保険完備（雇用・労災・健康・介護・厚生年金）`}
              rows='2'
            />
            <CollspanTextField
              title={'年間休日'}
              onClick={handleHideClick('days_off')}
              classes={classes}
              name={'days_off'}
              boolFlg={boolFlgHandler('days_off')}
              inputValue={jobOffer.days_off}
              onChange={handleChange('days_off')}
              placeholder={`（例）122日`}
              rows='2'
            />
            <CollspanTextField
              title={'休日・休暇制度'}
              onClick={handleHideClick('leave_system')}
              classes={classes}
              name={'leave_system'}
              boolFlg={boolFlgHandler('leave_system')}
              inputValue={jobOffer.leave_system}
              onChange={handleChange('leave_system')}
              placeholder={`（例）結婚休暇、忌引休暇、産前産後休暇、育児・介護看護休暇`}
              rows='2'
            />
            <CollspanTextField
              title={'福利厚生'}
              onClick={handleHideClick('employee_welfare')}
              classes={classes}
              name={'employee_welfare'}
              boolFlg={boolFlgHandler('employee_welfare')}
              inputValue={jobOffer.employee_welfare}
              onChange={handleChange('employee_welfare')}
              placeholder={`（例）持株会、積立貯蓄、財形制度、退職金制度`}
              rows='2'
            />
            <div className={classes.buttonArea} style={{ marginTop: 24 }}>
              <Button
                variant='outlined'
                color='primary'
                className={classes.button}
                aria-label='edit'
                onClick={handleUpdate}
              >
                求人情報を更新
              </Button>
            </div>
          </div>
          <div className={classes.companyEasyProfileWrapper}>
            <img
              src={profileImage}
              style={{
                width: 182,
                height: 182,
                objectFit: 'cover',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 20px 40px -14px'
              }}
              alt='Company Logo'
            />
            <h4 style={{ width: 188, fontSize: 15, marginBottom: 0 }}>
              {company.name}
            </h4>
            <p style={{ width: 188, fontSize: 10 }}>{company.address}</p>
            <p className={classes.companyIntroduction}>
              {company.introduction}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default EditField;

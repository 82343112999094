import {
  COMPANY,
  ADD_EMPLOYEE,
  UNREAD_NOTICE_COUNT,
  BOOL_TYPE_EMPLOYEE_CONF_TOGGLED,
} from '../actions/company';

export function company(state = {}, action) {
  switch (action.type) {
    case COMPANY:
      return action.payload.company.attributes.employees.data
        ? {
          ...action.payload.company.attributes,
          employees: action.payload.company.attributes.employees.data.map(data => data.attributes)
        }
        : { ...action.payload.company.attributes };
    case ADD_EMPLOYEE:
      return {
        ...state,
        errors: {
          error: { ...action.res }
        }
      };
    case BOOL_TYPE_EMPLOYEE_CONF_TOGGLED:
      const id = action.payload.data.employee_id;
      const key = action.payload.data.conf_key;
      const employees = state.employees.map(employee => {
        if (employee.id === id) {
          employee.conf[key] = !employee.conf[key];
          return employee;
        } else {
          return employee;
        }
      })
      return { ...state, employees: employees };
    default:
      return state;
  }
}

export function unread_count(state = 0, action) {
  switch (action.type) {
    case UNREAD_NOTICE_COUNT:
      return action.payload.unreadNoticeCount;
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';

const styles = theme => ({
  fab: {
    margin: theme.spacing(2)
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  }
});

function SimpleTooltips(props) {
  const { history, resetJobOfferStoreHandle } = props;
  return (
    <div style={{ position: 'absolute', right: 0, top: -32 }}>
      <Tooltip title="自社求人を新規作成" aria-label="add" placement="left"
               style={{ minHeight: 24, maxHeight: 24, width: 24, background: 'rgb(87, 40, 238)' }}
               onClick={async () => {
                await resetJobOfferStoreHandle();
                history.push('/recruit_new');
               }}>
        <Fab color="primary" >
          <AddIcon style={{ fontSize: '16px' }} />
        </Fab>
      </Tooltip>
    </div>
  );
}

SimpleTooltips.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(styles)(SimpleTooltips));

import React from 'react';
import { withRouter } from 'react-router-dom';

import { Modal, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  formControl: {
    margin: '15px 0'
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: 20
  }
}));

const ModalSelect = props => {
  const { closeModal, submitHandler, user_id, open, has_chat_rooms } = props;
  const classes = useStyles();

  const handleClose = () => {
    closeModal();
  };

  const handleProps = () => {
    submitHandler(user_id).then(() => {
      props.history.push('/inbox');
    });
  };
  const handleMoveChatRoom = () => {
    props.history.push(`/inbox`);
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Paper className={classes.paper}>
        <div className={classes.formControl}>
          {has_chat_rooms ? (
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleMoveChatRoom}
            >
              メッセージ作成画面に移動する
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              className={classes.button}
              onClick={handleProps}
            >
              メッセージ作成画面に移動する
            </Button>
          )}
          <Button
            onClick={handleClose}
            variant='contained'
            className={classes.button}
          >
            キャンセル
          </Button>
        </div>
      </Paper>
    </Modal>
  );
};

export default withRouter(ModalSelect);

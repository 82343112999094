import React from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { Grid, FormHelperText } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
const DatePicker = props => {
  const {
    selectDate,
    setSelectState,
    placeholder,
    name,
    format,
    openTo,
    views,
    minDate,
    maxDate
  } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid item>
        <FormHelperText style={{margin: 0}}>{placeholder}</FormHelperText>
        <KeyboardDatePicker
          variant="inline"
          format={format}
          openTo={openTo}
          views={views}
          id="date-picker-inline"
          value={selectDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={setSelectState(name)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          style={{ width: 196, marginTop: 6 }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;

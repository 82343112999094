import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  CardMedia,
  CardContent,
  Grid,
  Tabs,
  Tab,
  Button,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Layout from '../../components/Layout';
import SideDrawer from '../../components/common/Drawer';
import TabPanel from '../../components/common/TabPanel';
import EmployeeList from '../../components/Employees/list';
import Table from '../../components/Company/Table';
import JobOfferSimpleTable from '../../components/JobOffer/SimpleTable';
// import inputState from '../../state/ui/useInputState';

import { fetchCompany } from '../../actions/company';
import { fetchJobOffers, resetJobOffer } from '../../actions/jobOffers';
import { googleAnalytics } from '../../utils/pageGA';

// hookでstyle
const useStyles = makeStyles((theme) => ({
  card: {
    width: 1111,
  },
  containerGrid: {
    paddingLeft: 260,
  },
  media: {
    height: 222,
    width: 1111,
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  avatar: {
    margin: 10,
  },
  paper: {
    padding: 10,
  },
  list: {
    padding: 10,
  },
  scrollArea: {
    height: '370px',
    overflow: 'scroll',
  },
  nothingJobOffer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '18%',
  },
  tableHead: {
    backgroundColor: '#F2F2F2',
    fontWeight: 600,
  },
  table: {
    minWidth: 700,
  },
  breakLine: {
    whiteSpace: 'pre-line',
  },
  tablePointer: {
    cursor: 'pointer',
  },
  tabButton: {
    fontSize: 18,
    fontWeight: 600,
  },
  companyEasyProfileWrapper: {
    width: '22%',
    paddingLeft: 32,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  companyIntroduction: {
    width: 182,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: '16px 0 56px 0',
    letterSpacing: 1.1,
  },
}));

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const Company = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [value, setValue] = useState('one');
  // const { formObj, onChange } = inputState({
  //   name: '',
  //   email: '',
  // });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const resetJobOfferHandler = () => {
    dispatch(resetJobOffer());
  };

  const company = useSelector((state) => state.company);
  const job_offers = useSelector((state) => state.job_offers);
  const headerImage = company.header_image && company.header_image.url;
  const profileImage = company.profile_image && company.profile_image.url;

  const users = company.employees;
  const dispatch = useDispatch();
  // NOTE: 従業員登録用
  // const submitEmployee = () => {
  //   dispatch(createUser(formObj, company.id));
  // };
  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    dispatch(fetchCompany());
    dispatch(fetchJobOffers());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={7}>
          <SideDrawer />
          <div className={classes.card}>
            <CardMedia className={classes.media} image={headerImage || ''} />
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '78%' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='wrapped label tabs example'
                    style={{ marginBottom: 24 }}
                  >
                    <Tab
                      value='one'
                      label='会社概要'
                      className={classes.tabButton}
                      {...a11yProps('one')}
                    />
                    <Tab
                      value='two'
                      label='求人一覧'
                      className={classes.tabButton}
                      {...a11yProps('two')}
                    />
                    <Tab
                      value='three'
                      label='社員'
                      className={classes.tabButton}
                      {...a11yProps('three')}
                    />
                    <Button
                      variant='text'
                      aria-label='edit'
                      href='/company/edit'
                    >
                      <EditIcon style={{ fontSize: 16, marginRight: 3 }} />
                      会社情報を編集
                    </Button>
                  </Tabs>
                  <div
                    style={{ boxShadow: '0 20px 40px -14px rgba(0,0,0,0.25)' }}
                  >
                    <TabPanel value={value} index='one'>
                      <Table company={company} classes={classes} />
                    </TabPanel>
                    <TabPanel value={value} index='two'>
                      <JobOfferSimpleTable
                        classes={classes}
                        jobOffers={job_offers}
                        history={history}
                        resetJobOffer={resetJobOfferHandler}
                      />
                    </TabPanel>
                    <TabPanel
                      className={classes.scrollArea}
                      value={value}
                      index='three'
                    >
                      <EmployeeList users={users} />
                    </TabPanel>
                  </div>
                </div>
                {/* {value === 'three' && (
                  <EmployeeAddForm
                    formObj={formObj}
                    onChange={onChange}
                    submitEmployee={submitEmployee}
                    company={company}
                  />
                )} */}
                <div className={classes.companyEasyProfileWrapper}>
                  <img
                    src={profileImage}
                    style={{
                      width: 182,
                      height: 182,
                      objectFit: 'cover',
                      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 20px 40px -14px',
                    }}
                    alt='Company Logo'
                  />
                  <h4 style={{ width: 188, fontSize: 15, marginBottom: 0 }}>
                    {company.name}
                  </h4>
                  <p style={{ width: 188, fontSize: 10 }}>{company.address}</p>
                  <p className={classes.companyIntroduction}>
                    {company.introduction}
                  </p>
                </div>
              </div>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Company;

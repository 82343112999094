import React from 'react';

import {
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar
} from '@material-ui/core';

import { Link } from '../../components/common/Link';

import DefaultProfileImage from '../../images/default_images/default_profile_image.png';

export default function AlignItemsList({ student }) {
  const { name, image } = student.attributes.student_info;

  return (
    <Link to={`/student_detail/${student.id}`}>
      <ListItem alignItems="center">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={ image ? image : DefaultProfileImage } />
        </ListItemAvatar>
        <ListItemText primary={name} />
      </ListItem>
      <Divider variant="inset" component="li" />
    </Link>
  );
}

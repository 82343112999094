import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import deviseInvitation from '../../api/deviseInvitation';

import { googleAnalytics } from '../../utils/pageGA';

const styles = (theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
});

class SignUp extends React.Component {
  state = {
    password: '',
    password_confirmation: '',
  };
  onChangePassword = (e) => {
    this.setState({ password: e.target.value });
  };
  onChangePasswordConfirmation = (e) => {
    this.setState({ password_confirmation: e.target.value });
  };
  submitForm = (e) => {
    e.preventDefault();
    deviseInvitation
      .updateInvitable({
        ...this.state,
        invitation_token: window.location.search.slice(
          window.location.search.indexOf('=') + 1
        ),
      })
      .then((res) => {
        this.props.history.push('/signin');
      });
  };
  componentDidMount() {
    googleAnalytics({
      pathname: this.props.history.location.pathname,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            パスワード設定
          </Typography>
          <form className={classes.form}>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password'>パスワード</InputLabel>
              <Input
                name='password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={this.onChangePassword}
              />
            </FormControl>
            <FormControl margin='normal' required fullWidth>
              <InputLabel htmlFor='password_confirmation'>
                確認パスワード
              </InputLabel>
              <Input
                name='password_confirmation'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={this.onChangePasswordConfirmation}
              />
            </FormControl>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              onClick={this.submitForm}
            >
              パスワード設定
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  currentUser: state.reduxTokenAuth.currentUser,
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps)
)(SignUp);

import { useState } from 'react';

export default initialValue => {
  const [indicatorFlg, setBool] = useState(initialValue);
  return {
    indicatorFlg,
    toggleIndicator: (bool) => {
      setBool(bool)
    }
  }
}

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, makeStyles } from '@material-ui/core';

import Layout from '../../components/Layout';
import SideDrawer from '../../components/common/Drawer';
import CompanyForm from '../../components/Company/Form';
import {
  fetchCompany,
  editCompanyForm,
  updateCompany,
} from '../../actions/company';
import { googleAnalytics } from '../../utils/pageGA';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 1111,
  },
  containerGrid: {
    paddingLeft: 260,
  },
  media: {
    height: '100px',
  },
  dropArea: (company) => ({
    backgroundImage: `url(${
      company.profile_image && company.profile_image.url
    }`,
    backgroundRepeat: 'no-repeat',
    height: '200px',
    width: '200px',
  }),
}));

const CompanyEdit = (props) => {
  const { history } = props;

  const dispatch = useDispatch();
  const company = useSelector((state) => state.company);
  const classes = useStyles(company);

  const [file, setFile] = useState({});
  const [setFileError] = useState(false);

  const handleImageChange = (prop) => async (event) => {
    event.length > 1 &&
      ([event[event.length - 1], event[0]] = [
        event[0],
        event[event.length - 1],
      ]);
    dispatch(
      editCompanyForm({
        ...company,
        [prop]: { url: URL.createObjectURL(event[0]) },
      })
    );

    const imageLoad = (blob) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.src = URL.createObjectURL(blob);
      });
    };

    imageLoad(event[0]).then((res) => {
      switch (prop) {
        case 'profile_image':
          res.height < 182 && res.width < 182 && setFileError(true);
        // eslint-disable-next-line no-fallthrough
        case 'header_image':
          res.height < 222 && res.width < 1111 && setFileError(true);
        // eslint-disable-next-line no-fallthrough
        default:
          break;
      }
      // setFileSize({ ...res.height, ...res.width });
    });
    setFile(
      Object.assign(file, {
        [prop]: { url: URL.createObjectURL(event[0]), file: event[0] },
      })
    );
  };

  const handleUpdate = () => {
    dispatch(updateCompany({ ...company, ...file }));
  };

  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    dispatch(fetchCompany());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Layout>
      <SideDrawer />
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={12}>
          <CompanyForm
            values={company}
            inputValueUpdateHandler={editCompanyForm}
            imageChangeHandler={handleImageChange}
            updateHandler={handleUpdate}
            dispatcher={dispatch}
            history={history}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default CompanyEdit;

import React from 'react';
import { MenuItem, Select } from '@material-ui/core';

export default function SelectItem(props) {
  const { targetObj, selectObj, setSelectState } = props;

  return (
    <>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={selectObj}
        onChange={setSelectState}
      >
        {Object.keys(targetObj).map((k, index) => (
          <MenuItem value={targetObj[k].value} key={index}>
            {targetObj[k].name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

import storage from 'store2';

export function setAttr(attr) {
  // eslint-disable-next-line array-callback-return
  Object.keys(attr).map((k) => {
    storage.local.set(k, attr[k]);
  });
}

export function fetchCompanyName() {
  return storage.local.get('companyName');
}

import * as React from 'react';
import Header from './common/Header';
import Grid from '@material-ui/core/Grid';

const Layout = ({ children, history }) => (
  <div>
    <Header history={history} />
    <div className="main-container">
      <Grid container style={{ padding: 20, marginTop: 50 }}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
    <footer></footer>
  </div>
);

export default Layout;

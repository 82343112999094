import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 196
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default function SimpleSelect({ children, labelTitle }) {
  const classes = useStyles();

  return (
    <div>
      <FormControl className={classes.formControl}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-helper-label">
            {labelTitle}
          </InputLabel>
          {children}
        </FormControl>
      </FormControl>
    </div>
  );
}

import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Layout from '../../components/Layout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { fetchJobOffers, deleteJobOffer } from '../../actions/jobOffers';
import { googleAnalytics } from '../../utils/pageGA';

const styles = (theme) => ({
  tableRow: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
});

class RecruitIndex extends React.Component {
  deleteHandler = (e) => {
    const Id = e.currentTarget.value;
    this.props.deleteJobOffer(Id).then(() => {
      window.location.reload();
    });
  };
  componentDidMount() {
    googleAnalytics({
      pathname: this.props.history.location.pathname,
    });
    return this.props.fetchJobOffers();
  }
  render() {
    const { job_offers, classes } = this.props;
    return (
      <Layout>
        <Paper style={{ padding: 20 }}>
          <h2>募集一覧</h2>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>タイトル</TableCell>
                <TableCell>募集形態</TableCell>
                <TableCell>公開</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {job_offers.map((offer) => (
                <TableRow className={classes.tableRow}>
                  <TableCell>{offer.title}</TableCell>
                  <TableCell>{offer.tag.map((t) => `${t.name} `)}</TableCell>
                  <TableCell>{offer.publish ? '公開' : '非公開'}</TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        this.props.history.push(`/recruit/${offer.id}/edit`);
                      }}
                    >
                      編集
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='contained'
                      value={offer.id}
                      color='danger'
                      onClick={this.deleteHandler}
                    >
                      削除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  job_offers: state.job_offers,
});
export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, { fetchJobOffers, deleteJobOffer })
)(RecruitIndex);

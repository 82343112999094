import React from 'react';
import {
  Avatar,
  makeStyles,
  Typography,
  Breadcrumbs,
  Button,
  MenuItem,
  Select
} from '@material-ui/core';
import IconLabelButtons from '../Message/NewMailButton';
import SelectBox from './../common/SelectBox';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import DefaultProfileImage from '../../images/default_images/default_profile_image.png';

const useStyles = makeStyles((theme) => ({
  breadCrumb: {
    display: 'flex',
    alignItems: 'center',
  },
  Paper: {
    marginTop: 84,
    marginLeft: 100,
    marginRight: 100,
    padding: 20,
  },
  profileWrapper: {
    display: 'flex',
    width: '100%',
    padding: '2%',
  },
  iconStyle: {
    height: '60px',
    width: '60px',
  },
  kaneName: {
    fontSize: 10,
  },
  userProfileContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
  },
  snsContents: {
    display: 'flex',
    marginTop: 10,
  },
  imageShowStyle: {
    opacity: 0.5,
    heigth: 20,
    width: 20,
  },
  profileButton: {
    width: 124,
    height: 30,
    fontWeight: 600,
    position: 'relative',
    top: '18%',
  },
  chatBtn: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    margin: '0 4px',
  },
}));

const Profile = (props) => {
  const {
    course,
    age,
    name,
    snsObj,
    open,
    toggleHandle,
    image,
    snsHandler,
    children,
    desired_workplace_location,
    graduation_date,
    self_introduction,
    selfIntroductionSlidePublish,
    personalHistoryPublish,
    jobHistoryPublish,
    selfIntroductionSlideCb,
    personalHistoryCb,
    jobHistoryCb,
    affiliationList,
    changeAffiliationCb
  } = props;

  const snsArray = snsHandler(snsObj)();
  const classes = useStyles();

  const clickHandler = (cb) => async () => {
    const { data } = await cb();
    window.open(data);
  };

  return (
    <div style={{ width: 1000, marginLeft: 25, display: 'flex' }}>
      <div
        style={{
          marginRight: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <div style={{ position: 'absolute', left: 0 }}>{children}</div>
        <img
          src={image ? image : DefaultProfileImage}
          alt='Job Seeker Face'
          style={{
            borderRadius: '50%',
            width: 160,
            height: 160,
            objectFit: 'cover',
            boxShadow: '0 20px 40px -14px rgba(0,0,0,0.25)',
          }}
        />
        <Button
          size='small'
          variant='contained'
          color='primary'
          disabled={!selfIntroductionSlidePublish}
          className={classes.profileButton}
          onClick={clickHandler(selfIntroductionSlideCb)}
        >
          紹介スライド
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: 816 }}>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '80%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: 24, fontWeight: 'bold' }}>{name}</span>
              <span style={{ fontSize: 18, marginLeft: 12 }}>
                {age === null || age === '' ? null : `${age}歳`}
              </span>
              <span style={{ display: 'inline-flex', marginLeft: 24 }}>
                {snsArray.map((sns, index) =>
                  sns.path ? (
                    <a
                      href={sns.path}
                      rel='noopener noreferrer'
                      target='_blank'
                      key={index}
                    >
                      <Avatar
                        variant='rounded'
                        className={classes.small}
                        src={sns.imageUrl}
                      />
                    </a>
                  ) : (
                    null
                  )
                )}
              </span>
            </div>
            <div style={{ display: 'flex', margin: '8px 0' }}>
              <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                <span style={{ color: 'rgb(96 96 96)' }}>希望勤務地: </span>
                <span>
                  {desired_workplace_location === null ||
                  desired_workplace_location === ''
                    ? '________'
                    : `${desired_workplace_location}`}
                </span>
              </div>
            </div>
            <div>
              {
               affiliationList.length > 1 ?
               (<SelectBox labelTitle=''>
               <Select
                 onChange={(e) => {
                   changeAffiliationCb(e.target.value);
                 }}
                 value={course.current_affiliation_id}
               >
                 {affiliationList.map((af) => (
                   <MenuItem
                     value={af.id}
                     key={af.id}
                   >
                     {af.course_name}
                   </MenuItem>
                 ))}
               </Select>
             </SelectBox>)
                                          :
               (<Breadcrumbs
                separator={<NavigateNextIcon />}
                aria-label='breadcrumb'
              >
                <Typography color='textPrimary' style={{ fontWeight: 600 }}>
                  {course.name}
                </Typography>
                <Typography color='textPrimary' style={{ fontWeight: 600 }}>
                  {graduation_date}
                </Typography>
              </Breadcrumbs>)
              }
            </div>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 8 }}>
            <Button
              size='small'
              variant='contained'
              color='primary'
              disabled={!personalHistoryPublish}
              className={classes.profileButton}
              onClick={clickHandler(personalHistoryCb)}
            >
              履歴書
            </Button>
            <Button
              size='small'
              variant='contained'
              color='primary'
              disabled={!jobHistoryPublish}
              className={classes.profileButton}
              style={{ marginLeft: 32 }}
              onClick={clickHandler(jobHistoryCb)}
            >
              職務経歴書
            </Button>
          </div>
        </div>
        <p
          style={{
            fontSize: 16,
            fontWeight: 600,
            width: '100%',
            height: 75,
            margin: '8px 0 0 0',
            lineHeight: 1.6,
            color: 'rgb(96 96 96)',
          }}
        >
          { self_introduction ? self_introduction : '入力準備中...' }
        </p>
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}
        >
          <IconLabelButtons open={open} toggleHandle={toggleHandle} />
        </div>
      </div>
    </div>
  );
};

export default Profile;

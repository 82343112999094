import React from 'react';

import { makeStyles, Grid, List } from '@material-ui/core';

import InboxItem from '../Message/MessageItemList';
import StudentSearchInputField from '../OfferingStudents/StudentSearchInputField';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  cover: {
    width: '18%'
  },
  name: {
    marginBottom: '10px'
  },
  time: {
    marginLeft: '15px'
  },
  message: {
    fontSize: '0.9rem'
  },
  list: {
    width: 400,
    flexShrink: 0,
    marginTop: '10%',
    paddingBottom: 0
  },
  drawerPaper: {
    backgroundColor: '#fafafa'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(10)
  },
  toolbar: {
    ...theme.mixins.toolbar
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  drawer: {
    marginTop: '65px'
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    marginRight: 16,
    boxShadow: '0 20px 40px -14px rgba(0,0,0,0.25)',
  }
}));

const MessageBoxList = ({
  children,
  inboxes,
  showInboxes,
  searchHandler,
  clickHandler,
  checkedList
}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.messageContainer}>
      <div
        style={{
          maxHeight: 696,
          overflow: 'scroll',
          position: 'relative'
        }}
      >
        <StudentSearchInputField
          searchHandler={searchHandler}
          students={inboxes}
        />
        <List className={classes.list}>
          {showInboxes.map((inbox, index) => (
            <InboxItem
              inbox={inbox}
              clickHandler={clickHandler}
              checkedList={checkedList}
              key={index}
            />
          ))}
        </List>
      </div>
      {children}
    </Grid>
  );
};
export default MessageBoxList;

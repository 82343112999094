import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  makeStyles,
} from '@material-ui/core';

import SideDrawer from '../../components/common/Drawer';
import EditField from '../../components/JobOffer/EditField';

import Layout from '../../components/Layout';
import { fetchCompany } from '../../actions/company';
import {
  fetchJobOffer,
  editJobOfferForm,
  updateJobOffer,
} from '../../actions/jobOffers';

import { googleAnalytics } from '../../utils/pageGA';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 20,
    marginTop: 20,
  },
  containerGrid: {
    paddingLeft: 260,
  },
  card: {
    width: 1111,
  },
  media: {
    height: 222,
    width: 1111,
  },
}));

const RecruitEdit = (props) => {
  const { history } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const job_offer = useSelector((state) => state.job_offer);
  const company = useSelector((state) => state.company);
  const headerImage = company.header_image_url && company.header_image_url;
  const handleUpdate = async () => {
    await dispatch(updateJobOffer({ ...job_offer }));
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    if (job_offer.created) return history.push(`/recruit/${job_offer.id}`);
    dispatch(
      fetchJobOffer(
        window.location.pathname.slice([
          window.location.pathname.indexOf('/', 1) + 1,
        ])
      )
    );
    dispatch(fetchCompany());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.history, job_offer.created]);

  return (
    <Layout>
      <Grid container xs={12} className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={7}>
          <SideDrawer />
          <Card className={classes.card}>
            <CardMedia className={classes.media} image={headerImage || ''} />
            <CardContent>
              <EditField
                jobOffer={job_offer}
                dispatcher={dispatch}
                updateHandler={handleUpdate}
                inputValueUpdateHandler={editJobOfferForm}
                company={company}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RecruitEdit;

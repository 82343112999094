import React from 'react';
import {
  makeStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginLeft: 3,
  },
  formControl: {
    margin: 0,
  },
}));

export default function CheckboxesGroup(props) {
  const { checkState, setCheckState } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup className={classes.root} style={{ marginTop: 8 }}>
          <div style={{color: 'rgb(117 117 117)', fontSize: '0.75rem', marginLeft: 6 }}>受講コース</div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.WEB}
                  onChange={setCheckState('WEB')}
                  value={checkState.WEB}
                />
              }
              style={{ margin: 0 }}
            />
            <span>WEBエンジニアコース</span>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkState.ML}
                  onChange={setCheckState('ML')}
                  value={checkState.ML}
                />
              }
              style={{ margin: 0 }}
            />
            <span>機械学習エンジニアコース</span>
          </div>
        </FormGroup>
        <FormGroup className={classes.root} style={{ marginTop: 16 }}>
          <div style={{color: 'rgb(117 117 117)', fontSize: '0.75rem', marginLeft: 6 }}>受講ステータス</div>
          <div style={{ display: 'flex' }}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkState.graduate}
                    onChange={setCheckState('graduate')}
                    value={checkState.graduate}
                  />
                }
                style={{ margin: 0 }}
              />
              <span>卒業済み</span>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkState.inSchool}
                    onChange={setCheckState('inSchool')}
                    value={checkState.inSchool}
                  />
                }
                style={{ margin: 0 }}
              />
              <span>受講中</span>
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

export const MESSAGE_TOGGLE = "MESSAGE_TOGGLE";
export const CHAT_TOGGLE = "CHAT_TOGGLE";

export function toggleForm(bool) {
  return (dispatch) => {
    dispatch(toggleFlg(bool));
  };
}

export function chatToggleForm() {
  return (dispatch) => {
    dispatch(chatToggleFlg());
  };
}

function toggleFlg(bool) {
  return {
    type: MESSAGE_TOGGLE,
    bool
  };
}

function chatToggleFlg() {
  return {
    type: CHAT_TOGGLE
  };
}

import React from 'react';

import { Typography, LinearProgress } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const Progress = props => {
  const { classes, course } = props;
  return (
    <>
      {/* <Typography variant="body2" component="p" className={classes.pos}>
        <span>出席日数: {course.attendanceRecords}</span>
      </Typography>
      <Typography className={classes.pos}>
        <span>
          カリキュラム消化速度: 同期内 {course.rankingAmongColleagues}位
        </span>
      </Typography> */}

      <Typography variant="body2" component="div" className={classes.pos}>
        <div style={{ fontSize: 22, marginBottom: 8 }}>
          <FlagIcon style={{ marginRight: 6 }}/>
          <span style={{ marginRight: 8 }}>テキスト完了率: </span>
          <span style={{ fontSize: 32, color: 'rgb(226, 50, 90)' }}>{course.textCompleteRate} </span>
          %
        </div>
        <LinearProgress
          value={course.textCompleteRate}
          variant="determinate"
          style={{ height: 24 }}
        />
      </Typography>
      <Typography variant="body2" component="div" className={classes.pos}>
        <div style={{ fontSize: 22, marginBottom: 8 }}>
          <DoneAllIcon style={{ marginRight: 6 }}/>
          <span style={{ marginRight: 8 }}>課題突破率: </span>
          <span style={{ fontSize: 32, color: 'rgb(226, 50, 90)' }}>{course.submissionCompleteRate} </span>
          %
        </div>
        <LinearProgress
          value={course.submissionCompleteRate}
          variant="determinate"
          style={{ height: 24 }}
        />
      </Typography>
    </>
  );
};

export default Progress;
// min-height: 333px;
// box-shadow: 0 20px 40px -14px rgba(0,0,0,0.25);
// border-radius: 0;
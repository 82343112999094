import sanitize from 'sanitize-html';

export function createMarkup(html) {
  return {
    __html: washedHTML(html)
  };
}

export function washedHTML(html, cb = () => []) {
  return sanitize(html, {
    allowedTags: sanitize.defaults.allowedTags.filter(cb).concat([
      'img'
    ]),
    allowedClasses: {
      '*': [ // 全てタグに対して以下のクラスの付与は許可している
        'ogp_container_href',
        'ogp_maincontainer',
        'ogp_image_container_width',
        'ogp_image_width',
        'ogp_title',
        'download_link'
      ]
    },
    transformTags: {
      'img': function (tagName, attribs) {
        return {
          tagName: 'img',
          attribs: {
            ...attribs,
            src: attribs.src.replace(/<|>/g, "")
          }
        };
      }
    },
  });
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CleanHtml from '../common/CleanHtml';
import HtmlConverter from '../../utils/HtmlConverter';

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: '#F2F2F2',
    fontWeight: 600,
    width: 238
  },
  table: {
    minWidth: 700
  },
  breakLine: {
    whiteSpace: 'pre-wrap'
  }
});

const SpanningTable = props => {
  const classes = useStyles();
  const { jobOffer } = props;

  return (
    <Table className={classes.table} aria-label='spanning table'>
      <TableBody>
        <h3>仕事について</h3>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            仕事内容
          </TableCell>
          <TableCell className={classes.breakLine}>
            <CleanHtml dirtyHtml={HtmlConverter.stringToAtag(jobOffer.content)} />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            開発環境
          </TableCell>
          <TableCell className={classes.breakLine}>
            <CleanHtml dirtyHtml={HtmlConverter.stringToAtag(jobOffer.development_environment)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            サポート体制
          </TableCell>
          <TableCell className={classes.breakLine}>
            <CleanHtml dirtyHtml={HtmlConverter.stringToAtag(jobOffer.support_system)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            1日の流れ
          </TableCell>
          <TableCell className={classes.breakLine}>
            <CleanHtml dirtyHtml={HtmlConverter.stringToAtag(jobOffer.day_flow)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            働き方
          </TableCell>
          <TableCell className={classes.breakLine}>
            <CleanHtml dirtyHtml={HtmlConverter.stringToAtag(jobOffer.work_style)} />
          </TableCell>
        </TableRow>

        <div style={{ margin: '50px 0' }}></div>
        <h3>募集要項</h3>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            職種
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.job_type}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            想定年収
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.estimated_annual_income}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            求める人物像
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.ideal_candidate}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            雇用形態
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.contract_type}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            採用予定人数
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.recruitment_numbers}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            勤務時間
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.working_hours}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            残業時間
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.overtime_schedule}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            選考フロー
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.hiring_process}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            入社時期
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.joining_date}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            選考詳細
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.hiring_detail}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            勤務地
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.workplace_address}
          </TableCell>
        </TableRow>

        <div style={{ margin: '50px 0' }}></div>
        <h3>仕事環境</h3>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            試用期間
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.trial_period}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            待遇（手当てなど）
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.allowance}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            年間休日
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.days_off}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            休日・休暇制度
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.leave_system}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant='head' className={classes.tableHead}>
            福利厚生
          </TableCell>
          <TableCell className={classes.breakLine}>
            {jobOffer.employee_welfare}
          </TableCell>
        </TableRow>
        <div style={{ margin: '50px 0' }}></div>
      </TableBody>
    </Table>
  );
};

export default SpanningTable;

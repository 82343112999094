import {
  CHAT_ROOM,
  INBOXES,
  CHAT_MESSAGE,
  SEARCH_INBOXES,
  ADD_INBOXES,
  WAITING_MESSAGE
} from '../actions/chat';
import { escapeStringToMatchWord } from '../utils/EscapeString';

const initialChatRoomState = {
  user_id: null,
  recruit_company_id: null,
  room_id: null,
  employee_id: null
};

const initialChatMessageState = {
  employee: {},
  chat_student: {},
  messages: []
};
export function chat_room(state = initialChatRoomState, action) {
  switch (action.type) {
    case CHAT_ROOM:
      return { ...action.room.attributes };
    default:
      return state;
  }
}

export function inboxes(state = [], action) {
  switch (action.type) {
    case INBOXES:
      return action.inboxes
        .sort((a, b) => {
          if (
            a.attributes.last_create_message_time <
            b.attributes.last_create_message_time
          ) {
            return 1;
          }
          if (
            a.attributes.last_create_message_time >
            b.attributes.last_create_message_time
          ) {
            return -1;
          }
          return 0;
        })
        .map(inbox => inbox.attributes);
    case ADD_INBOXES:
      return state.map(messageRoom =>
        messageRoom.user_id === action.messages.attributes.user_id
          ? { ...action.messages.attributes }
          : { ...messageRoom }
      );
    default:
      return state;
  }
}

export function messages(state = initialChatMessageState, action) {
  const JOB_OFFER_LINK = /(https?:\/\/.*)\/dive_into_work\/company\/(\d+)\/job_offer\/(\d+)/
  switch (action.type) {
    case CHAT_MESSAGE:
      return {
        ...action.messages.attributes,
        messages: action.messages.attributes.messages.map(data => {
          return (
            {
              ...data,
              message: data.message.replace(JOB_OFFER_LINK, "https://work.diveintocode.jp/recruit/$3")
            }
          )
          }
        )
      };
    case WAITING_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.messages]
      }
    default:
      return state;
  }
}

export function search_inboxes(state = [], action) {
  switch (action.type) {
    case SEARCH_INBOXES:
      const match = escapeStringToMatchWord(action.query);
      return action.inboxes.filter(element =>
        match.test(element.chat_student.name)
      );
    default:
      return state;
  }
}

import ReactGA from 'react-ga';
import { fetchCompanyName } from './LocalStorageHandle';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
// note: ローカルで試すときはtracking id取得してください
// ReactGA.initialize('UA-XXXXX', { debug: true });

const trackPage = ({ pathname, name }) => {
  ReactGA.set({
    dimension1: name === undefined ? fetchCompanyName() : name,
  });
  ReactGA.pageview(pathname);
};

export const googleAnalytics = ({ pathname, name }) => {
  trackPage({ pathname, name });
};

import React, { Component } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';

class DropZoneImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileError: false
    };
  }
  handleChange(files) {
    const imageLoad = blob => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.src = URL.createObjectURL(blob);
      });
    };

    imageLoad(files[0]).then(res => {
      switch (this.props.attributeName) {
        case 'profile_image':
          res.height < 400 &&
            res.width < 400 &&
            this.setState({ fileError: true });
        // eslint-disable-next-line no-fallthrough
        case 'header_image':
          res.height < 222 &&
            res.width < 1111 &&
            this.setState({ fileError: true });
        // eslint-disable-next-line no-fallthrough
        default:
          break;
      }
    });
  }
  getFileAddedMessage() {
    console.log(this.state.fileError);
    // return '適切な画像サイズです。';
  }
  getFileLimitExceedMessage() {
    return '画像の設定が失敗しました。お手数ですがページのリロード後に再度お試しください。';
  }
  render() {
    // NOTE: filesLimitはupdate時にdropできる回数
    // 本来ならdropするたびにfileオブジェクトを削除したかったがやり方わからなかったり, $rでアクセスできたりできなかったりなので回数を設定
    return (
      <DropzoneArea
        onChange={async event => {
          this.props.imageHandler(event);
          await this.handleChange(event);
        }}
        filesLimit={10}
        dropzoneClass={this.props.dropzoneClass}
        getFileAddedMessage={this.getFileAddedMessage.bind(this)}
        getFileLimitExceedMessage={this.getFileLimitExceedMessage.bind(this)}
        showPreviewsInDropzone={false}
        // dropzoneText={`${this.props.imageSizeDesc}の画像を設定してください`}
        dropzoneText={null}
        showAlerts={false}
      />
    );
  }
}

export default DropZoneImage;

import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import NewOfferToolTip from './newOfferToolTip';
import moment from 'moment';
const messageTime = createTime => {
  return moment(createTime).calendar();
};
const SimpleTable = props => {
  const { classes, jobOffers, history, resetJobOffer } = props;
  return (
    <>
      <div style={{ position: 'relative' }}>
        <NewOfferToolTip resetJobOfferStoreHandle={resetJobOffer} />
        <Paper className={classes.jobOffersTable} 
               style={{ width: '100%',
                        overflow: 'scroll',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 20px 24px -14px',
                        borderRadius: 0 }}>
          {jobOffers.length > 0 ? (
            <List className={classes.list} style={{ padding: '8px 0', width: '100%' }}>
              {jobOffers.map((offer, index) => (
                <ListItem
                  button
                  onClick={() => {
                    history.push(`/recruit/${offer.id}`);
                  }}
                  key={index}
                  style={{ borderBottom: 'solid 0.2px lightgray', paddingLeft: 8 }}
                >
                  {
                    offer.publish ?
                      <Button variant="outlined" color="primary" size="small"
                       style={{height: 24, fontSize: 12, padding: 0, marginRight: 6}}>
                        公開中
                      </Button>
                      :
                      <Button variant="outlined" color="secondary"
                       style={{height: 24, fontSize: 12, padding: 0, marginRight: 6}}>
                        非公開
                      </Button>
                  }
                  <ListItemText
                    primary={offer.title}
                    style={{ display: 'inline-block',
                             whiteSpace: 'nowrap',
                             width: '70%',
                             overflow: 'hidden',
                             textOverflow: 'ellipsis' }}
                  />
                  <ListItemText
                    style={{width: '15%'}}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          style={{ fontSize: '12px', display: 'flex', justifyContent: 'flex-end' }}
                        >
                          {messageTime(offer.created_at)}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="subtitle1" className={classes.nothingJobOffer}
                        style={{ margin: '18%'}}>
              求人が作成されていません
            </Typography>
          )}
        </Paper>
      </div>
    </>
  );
};

export default SimpleTable;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUnreadNotice } from '../../actions/company';
import {
  AppBar,
  makeStyles,
  Typography,
  Toolbar,
  Badge
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import TurnedInIcon from '@material-ui/icons/TurnedInNot';
import EmailIcon from '@material-ui/icons/MailOutlineOutlined';

import LogOutButton from './LogOutButton';
import MainLogo from '../../images/diveintowork_logo.png';

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: ['none'],
    background: "#fff",
    height: 50
  },
  title: {
    color: '#ffffff',
    marginRight: '50px'
  },
  menu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  menuRight: {
    display: 'flex',
    flexDirection: 'row'
  },
  students: {
    textDecoration: 'none',
    paddingRight: 30
  },
  dashboard: {
    paddingRight: 30
  },
  diw_logo: {
    width: '222px',
    height: '30px',
    backgroundImage: `url(${MainLogo})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  }
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const unreadCount = useSelector(state => state.unread_count);

  const clickHandler = name => () => {
    window.location.replace(`/${name}`);
  };

  useEffect(() => {
    const pooling = setInterval(() => {
      dispatch(fetchUnreadNotice());
    }, 100000);
    return () => clearInterval(pooling);
  }, [dispatch]);

  return (
    <AppBar position="fixed" color="default" className={classes.appBar}>
      <Toolbar>
        <div className={classes.menu}>
          <div
            className={classes.diw_logo}
            onClick={clickHandler('')}
            style={{ cursor: 'pointer' }}
          ></div>
          <Typography
            variant="h5"
            className={classes.title}
            color="inherit"
            noWrap
          ></Typography>
          <div style={{ display: 'flex' }}>
            <div className={classes.menuRight}>
              <Typography
                className={classes.students}
                variant="subtitle1"
                color="inherit"
                noWrap
              >
                <SearchIcon
                  onClick={clickHandler('offering_students')}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
              <Typography
                className={classes.dashboard}
                variant="subtitle1"
                color="inherit"
                noWrap
              >
                <TurnedInIcon
                  onClick={clickHandler('offering_students?favorite=true')}
                  style={{ cursor: 'pointer' }}
                />
              </Typography>
              <Typography
                className={classes.dashboard}
                variant="subtitle1"
                color="inherit"
                noWrap
              >
                <Badge
                  badgeContent={unreadCount}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  style={{ display: 'flex', bottom: 1 }}
                >
                  <EmailIcon
                    onClick={clickHandler('inbox')}
                    style={{ cursor: 'pointer' }}
                  />
                </Badge>
              </Typography>
            </div>
            <LogOutButton />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import React, { Component } from 'react';
import { BrowserRouter, Route, withRouter, Switch } from 'react-router-dom';
import { routes } from '../routes';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {routes.map((route, i) => (
            <Route
              path={route.path}
              exact={route.exact}
              component={route.main()}
              key={i}
            />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default withRouter(App);

import API_CLIENT from '../utils/HttpClient';

export default {
  getAll() {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/recruit_company/recruit_employees'
    });
  },
  updateEmployee({ data }) {
    const { id } = data;
    return API_CLIENT({
      method: 'PUT',
      url: `/api/v2/recruit/recruit_company/recruit_employees/${id}`,
      data: setForm({ data })
    });
  },
  deleteEmployee(Id) {
    return API_CLIENT({
      method: 'DELETE',
      url: `/api/v2/recruit/recruit_company/recruit_employees/${Id}`
    });
  },
  resetPassword(email) {
    return API_CLIENT({
      method: 'POST',
      url: '/api/v2/recruit_employee/auth/password',
      params: {
        email: email,
        redirect_url: `${window.location.origin}/new_password`,
      }
    })
  },
  updatePassword(data) {
    return API_CLIENT({
      method: 'PUT',
      url: '/api/v2/recruit_employee/auth/password',
      data: data,
    });
  },
};

function setForm({ data }) {
  const { name, position, email } = data;
  const formData = new FormData();
  formData.append('recruit_employee[name]', name);
  formData.append('recruit_employee[position]', position);
  formData.append('recruit_employee[email]', email);
  return formData;
}

import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2)
  }
}));

const CircularIndeterminate = () => {
  const classes = useStyles();
  return (
    <>
      <CircularProgress className={classes.progress} color="secondary" />
    </>
  );
};

export default CircularIndeterminate;

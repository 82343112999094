import { USERS, DELETE_USER } from '../actions/users';

export function users(state = [], action) {
  switch (action.type) {
    case USERS:
      return action.users;
    case DELETE_USER:
      return state.filter(user => user.id !== parseInt(action.id));
    default:
      return state;
  }
}

import API_CLIENT from '../utils/HttpClient';

export default {
  getStudents(page, row_count, searchKey) {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/students',
      params: { page, row_count, ...searchKey },
    });
  },
  getStudent(Id) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/students/${Id}`,
      withCredentials: true
    });
  },
  createFavorite({ studentId }) {
    return API_CLIENT({
      method: 'POST',
      url: `/api/v2/recruit/students/${studentId}/favorite_students`,
    });
  },
  deleteFavorite({ studentId, favoriteId }) {
    return API_CLIENT({
      method: 'DELETE',
      url: `/api/v2/recruit/students/${studentId}/favorite_students/${favoriteId}`,
    });
  },
  fetchSelfIntroductionSlide({ studentId }) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/students/${studentId}/self_introduction_slide`,
    });
  },
  fetchPersonalHistory({ studentId }) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/students/${studentId}/personal_history`,
    });
  },
  fetchJobHistory({ studentId }) {
    return API_CLIENT({
      method: 'GET',
      url: `/api/v2/recruit/students/${studentId}/job_history`,
    });
  },
};

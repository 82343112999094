import API_CLIENT from '../utils/HttpClient';

export default {
  getOwn() {
    return API_CLIENT({
      method: 'GET',
      url: '/api/v2/recruit/recruit_company'
    });
  },
  updateCompany(data) {
    return API_CLIENT({
      method: 'PUT',
      url: `/api/v2/recruit/recruit_company`,
      data: setForm(data)
    });
  },
  toggleBoolTypeEmployeeConf(confId, key) {
    return API_CLIENT({
      method: 'PUT',
      url: `/api/v2/recruit/recruit_company/recruit_employee_confs/${confId}/toggle_${key}`,
    });
  },
};

function setForm(data) {
  const formData = new FormData();
  formData.append('recruit_company[name]', data.name);
  data.rep_name && formData.append('recruit_company[rep_name]', data.rep_name);
  data.address && formData.append('recruit_company[address]', data.address);
  data.home_page_url &&
    formData.append('recruit_company[home_page_url]', data.home_page_url);
  data.description &&
    formData.append('recruit_company[description]', data.description);
  data.introduction &&
    formData.append('recruit_company[introduction]', data.introduction);
  data.founded_date &&
    formData.append('recruit_company[founded_date]', data.founded_date);
  data.capital && formData.append('recruit_company[capital]', data.capital);
  data.employee_number &&
    formData.append('recruit_company[employee_number]', data.employee_number);
  data.avg_age && formData.append('recruit_company[avg_age]', data.avg_age);
  data.customers &&
    formData.append('recruit_company[customers]', data.customers);
  data.main_stock_holder &&
    formData.append(
      'recruit_company[main_stock_holder]',
      data.main_stock_holder
    );
  data.business_condition &&
    formData.append(
      'recruit_company[business_condition]',
      data.business_condition
    );
  // ここではfileオブジェクトが欲しい
  data.header_image.file &&
    formData.append('recruit_company[header_image]', data.header_image.file);
  data.profile_image.file &&
    formData.append('recruit_company[profile_image]', data.profile_image.file);
  return formData;
}

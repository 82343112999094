import React from 'react';
import moment from 'moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TIME_FORMAT = 'YYYY-MM-DD';

const SpanningTable = props => {
  const { company, classes } = props;

  return (
    <Table className={classes.table} aria-label="spanning table">
      <TableBody>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>事業内容</TableCell>
          <TableCell className={classes.breakLine}>
            {company.description}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>本社所在地</TableCell>
          <TableCell className={classes.breakLine}>
            {company.address}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>代表者氏名</TableCell>
          <TableCell className={classes.breakLine}>
            {company.rep_name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>ホームページ</TableCell>
          <TableCell className={classes.breakLine}>
            {company.home_page_url}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>設立年月日</TableCell>
          <TableCell className={classes.breakLine}>
            {moment(company.founded_date).format(TIME_FORMAT)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>従業員数</TableCell>
          <TableCell className={classes.breakLine}>
            {company.employee_number}名
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>資本金</TableCell>
          <TableCell className={classes.breakLine}>
            {new Intl.NumberFormat().format(company.capital)}万円
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell variant="head" className={classes.tableHead}>主要株主</TableCell>
          <TableCell className={classes.breakLine}>
            {company.main_stock_holder}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
};

export default SpanningTable;

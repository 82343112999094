import React from 'react';

import { Paper, Typography } from '@material-ui/core';
import TimeFormat from '../../utils/TimeFormat';
const StudyTime = props => {
  const { classes, studyTime } = props;
  return (
    <div className={classes.studyTime}>
      <Paper style={{ height: '70px', width: '33%' }}>
        <Typography
          align="center"
          variant="h5"
          className={classes.studyTimeLabel}
        >
          合計学習時間
        </Typography>
        <Typography
          align="center"
          className={classes.centerling}
          color="inherit"
          variant="h5"
        >
          {TimeFormat.toHours(studyTime.all)}時間
        </Typography>
      </Paper>

      <Paper style={{ height: '70px', width: '33%' }}>
        <Typography
          align="center"
          variant="h5"
          className={classes.studyTimeLabel}
        >
          今週
        </Typography>
        <Typography className={classes.centerling} color="inherit" variant="h5">
          <span>{TimeFormat.toHours(studyTime.week)}</span>時間
        </Typography>
      </Paper>

      <Paper style={{ height: '70px', width: '33%' }}>
        <Typography
          align="center"
          variant="h5"
          className={classes.studyTimeLabel}
        >
          今日
        </Typography>
        <Typography className={classes.centerling} color="inherit" variant="h5">
          <span>{TimeFormat.toMinutes(studyTime.today)}</span>分
        </Typography>
      </Paper>
    </div>
  );
};

export default StudyTime;

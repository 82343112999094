import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { fetchUsers } from '../../actions/users';
import { fetchJobOffers, resetJobOffer } from '../../actions/jobOffers';
import { fetchExpectedGraduatesStudents } from '../../actions/students';
import { getInbox } from '../../actions/chat';
import { fetchCompany } from '../../actions/company';

import {
  Button,
  Grid,
  makeStyles,
  Divider,
  Typography,
  List,
  ListSubheader,
} from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EmailIcon from '@material-ui/icons/MailOutlineOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import Layout from '../../components/Layout';

import SideDrawer from '../../components/common/Drawer';
import JobOfferSimpleTable from '../../components/JobOffer/SimpleTable';
import StudentProfile from '../../components/OfferingStudents/StudentCard4DashBoard';
import InboxItem from '../../components/Message/MessageItemList';

import NewStudentList from '../../components/common/NewStudentList';

import useIndicatorState from '../../state/ui/useIndicatorState';
import { googleAnalytics } from '../../utils/pageGA';
import { setAttr } from '../../utils/LocalStorageHandle';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 10,
    overflow: 'scroll',
    width: '400px',
  },
  profile: {
    width: '30%',
  },
  title: {
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  SlideArea: {
    // width: '100%',
    // textAlign: 'right'
    position: 'absolute',
    right: 0,
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  nothingJobOffer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20%',
  },
  jobOffersTable: {
    height: 248,
  },
}));

const Dashboard = (props) => {
  const { history } = props;
  const { toggleIndicator } = useIndicatorState(true);
  const [pageNationCount, changePangeNationCount] = useState([0, 3]);
  const [pageNum, setPageNum] = useState(1);

  const dispatch = useDispatch();
  const students = useSelector((state) => state.students);
  const newRegisterdStudents = useSelector(
    (state) => state.newRegisterdStudents
  );

  const job_offers = useSelector((state) => state.job_offers);
  const inboxes = useSelector((state) => state.inboxes);

  const displayPrevStudents = () => {
    changePangeNationCount([pageNationCount[0] - 3, pageNationCount[1] - 3]);
  };
  const displayNextStudents = async () => {
    if (students.slice(pageNationCount[0], pageNationCount[1]).length < 3) {
      await dispatch(fetchExpectedGraduatesStudents([pageNum + 1, 10]));
      setPageNum(pageNum + 1);
    }
    changePangeNationCount([pageNationCount[0] + 3, pageNationCount[1] + 3]);
  };

  const clickHandle = (studentId) => {
    history.push(`/inbox?studentId=${studentId}`);
  };

  const resetJobOfferHandler = () => {
    dispatch(resetJobOffer());
  };

  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    toggleIndicator(true);
    (async () => {
      const res = await dispatch(fetchCompany());
      const { name } = res.data.data.attributes;
      setAttr({ companyName: name });
      googleAnalytics({
        pathname: history.location.pathname,
        name: name,
      });
    })();
    Promise.all([
      dispatch(fetchUsers()),
      dispatch(fetchJobOffers()),
      dispatch(
        fetchExpectedGraduatesStudents([0, 10, { graduateDate: new Date() }])
      ),
      dispatch(getInbox()),
    ]).catch((err) => {
      props.history.push('/signin');
    });
    toggleIndicator(false);
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  return (
    <Layout>
      <SideDrawer>
        <List
          subheader={
            <ListSubheader component='div' id='nested-list-subheader'>
              新規求職者
            </ListSubheader>
          }
        >
          <div style={{ overflow: 'scroll', height: '800px' }}>
            {newRegisterdStudents.map((student) => (
              <NewStudentList student={student} key={student.id} />
            ))}
          </div>
        </List>
      </SideDrawer>

      <Grid container style={{ paddingLeft: 196 }} justify='center'>
        <Grid container direction='row' justify='center'>
          <Grid item xs={12} sm={6}>
            <Typography
              variant='h6'
              className={classes.contentTitle}
              style={{ marginBottom: '8px' }}
            >
              <FormatListBulletedIcon />
              <span style={{ marginLeft: '8px' }}>自社求人</span>
            </Typography>
            <JobOfferSimpleTable
              classes={classes}
              jobOffers={job_offers}
              history={history}
              resetJobOffer={resetJobOfferHandler}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <List
              className={classes.list}
              style={{
                marginLeft: 48,
                padding: 0,
                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 20px 40px -14px',
              }}
            >
              <Typography
                variant='h6'
                className={classes.contentTitle}
                style={{ marginBottom: '8px' }}
              >
                <EmailIcon />
                <span style={{ marginLeft: '8px' }}>メッセージ</span>
              </Typography>
              <div
                style={{
                  height: '248px',
                  overflow: 'scroll',
                  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 16px 24px -14px',
                }}
              >
                {inboxes.map((inbox, index) => (
                  <InboxItem
                    inbox={inbox}
                    clickHandler={clickHandle}
                    key={index}
                  />
                ))}
              </div>
            </List>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justify='center'
          style={{ marginTop: 32 }}
        >
          <Grid
            item
            className={classes.content}
            xs={12}
            sm={7}
            style={{ position: 'relative' }}
          >
            <div className={classes.title}>
              <Typography variant='h6' className={classes.contentTitle}>
                <TrendingUpIcon />
                <span style={{ marginLeft: '8px' }}>今月の卒業予定者</span>
                <span className={classes.SlideArea}>
                  {pageNationCount[0] > 0 && (
                    <Button
                      onClick={displayPrevStudents}
                      style={{ padding: 0 }}
                    >
                      <ChevronLeftIcon style={{ fontSize: '33px' }} />
                    </Button>
                  )}
                  {students.slice(pageNationCount[0], pageNationCount[1])
                    .length !== 0 && (
                    <Button
                      onClick={displayNextStudents}
                      style={{ padding: 0 }}
                    >
                      <ChevronRightIcon style={{ fontSize: '33px' }} />
                    </Button>
                  )}
                </span>
              </Typography>
              <Divider style={{ width: '97%' }} />
            </div>
            {students.length !== 0
              ? students
                  .slice(pageNationCount[0], pageNationCount[1])
                  .map((student, index) => {
                    return (
                      <div className={classes.profile} key={index}>
                        <Grid container spacing={2}>
                          <StudentProfile key={index} student={student} />
                        </Grid>
                      </div>
                    );
                  })
              : null}
          </Grid>
          <Grid item xs={12} sm={3}>
            {/* ここにイベント機能のwidget入る予定 */}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;

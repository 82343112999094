import {
  STUDENTS,
  STUDENT,
  SEARCH_STUDENTS,
  GRADUATE_STUDENTS,
  UPDATE_FAVORITE,
  UPDATE_FAVORITE_STUDENT,
  NEW_REGISTERED_STUDENTS,
} from '../actions/students';
import { MODAL } from '../actions/util';
import { escapeStringToMatchWord } from '../utils/EscapeString';

function defaultState() {
  return {
    attributes: {
      student_info: {
        age: 0,
        message_flg: false,
        academic_background: '',
        name: '',
        development_experience: '',
        github_url: '',
        qiita_url: '',
        wantedly_url: '',
        user_id: 0,
        name_kana: '',
      },
    },
    modal: {
      // コンポーネントのstateに移動しても良いかも
      open: false,
    },
  };
}

export function students(state = [], action) {
  switch (action.type) {
    case STUDENTS:
      return action.students;
    case GRADUATE_STUDENTS:
      return [...state, ...action.students];
    case UPDATE_FAVORITE:
      return state.map((obj) => {
        if (obj.attributes.student_info.user_id === action.studentId) {
          return {
            ...obj,
            attributes: {
              ...obj.attributes,
              student_info: {
                ...obj.attributes.student_info,
                isBookMark: action.isBookMark,
              },
            },
          };
        }
        return obj;
      });
    default:
      return state;
  }
}

export function newRegisterdStudents(state = [], action) {
  switch (action.type) {
    case NEW_REGISTERED_STUDENTS:
      return action.new_registerd_students;
    default:
      return state;
  }
}

export function student(state = defaultState(), action) {
  switch (action.type) {
    case STUDENT:
      return {
        ...action.student,
        ...state.modal,
      };
    case MODAL:
      return {
        ...state,
        open: !state.open,
      };
    case UPDATE_FAVORITE_STUDENT:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          student_info: {
            ...state.attributes.student_info,
            favorite_id: action.favoriteId,
            isBookMark: action.isBookMark,
          },
        },
      };
    default:
      return state;
  }
}

export function search_students(state = [], action) {
  switch (action.type) {
    case SEARCH_STUDENTS:
      const match = escapeStringToMatchWord(action.query);
      return {
        data: action.students.data.filter((element) =>
          match.test(element.attributes.student_info.name)
        ),
      };
    default:
      return [];
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { signOutUser } from '../../redux-token-auth';
import { deleteToken } from '../../utils/ResetToken';
class LogOutButton extends React.Component {
  signOut = e => {
    e.preventDefault();
    const { signOutUser } = this.props;
    signOutUser()
      .then(() => deleteToken())
      .then(() => this.props.history.push('/signin'));
  };
  render() {
    return (
      <Button style={{ height: 24, padding: '0 0 10px 0' }} onClick={this.signOut}>ログアウト</Button>
    );
  }
}

export default compose(
  withRouter,
  connect(null, { signOutUser })
)(LogOutButton);

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Button,
  makeStyles,
  Typography,
  Chip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SideDrawer from '../../components/common/Drawer';
import Table from '../../components/JobOffer/Table';
import DeleteDialog from '../../components/common/deleteDialog';

import Layout from '../../components/Layout';
import { fetchCompany } from '../../actions/company';
import { fetchJobOffer, deleteJobOffer } from '../../actions/jobOffers';

import { googleAnalytics } from '../../utils/pageGA';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 20,
    marginTop: 20,
  },
  containerGrid: {
    paddingLeft: 260,
  },
  card: {
    width: 1111,
  },
  media: {
    height: 222,
    width: 1111,
  },
  editPos: {
    textAlign: 'right',
  },
  categoryChip: {
    color: 'rgb(182, 182, 182)',
    fontSize: 16,
    padding: 16,
    fontWeight: 600,
  },
  jobOfferTitle: {
    textAlign: 'center',
    borderBottom: '1px solid black',
    lineHeight: '50px',
    fontWeight: 'bold',
    marginTop: 16,
  },
  companyEasyProfileWrapper: {
    width: '22%',
    paddingLeft: 32,
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  companyIntroduction: {
    width: 182,
    fontSize: 14,
    whiteSpace: 'pre-line',
    margin: '16px 0 56px 0',
    letterSpacing: 1.1,
  },
}));

const RecruitShow = (props) => {
  const { history } = props;
  const [showDialog, setDialogStatus] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const job_offer = useSelector((state) => state.job_offer);
  const company = useSelector((state) => state.company);
  const headerImage = company.header_image_url && company.header_image_url;
  const profileImage = company.profile_image && company.profile_image.url;
  const displayCategory = (category) => {
    switch (category) {
      case 'web':
        return 'Webエンジニアコース向け';
      case 'ml':
        return '機械学習エンジニアコース向け';
      default:
        return '指定なし';
    }
  };

  const dialogContext = {
    title: '本当に求人を削除していいですか？',
    content: '削除するを押すと求人が削除されます。',
    modalState: showDialog,
    closeAction: () => setDialogStatus(false),
    cb: () => deleteHandler(),
  };
  const deleteHandler = async () => {
    await dispatch(deleteJobOffer(job_offer.id));
    history.push('/');
  };

  const dialogOpenHandler = () => {
    setDialogStatus(true);
  };

  useEffect(() => {
    googleAnalytics({
      pathname: history.location.pathname,
    });
    dispatch(
      fetchJobOffer(
        window.location.pathname.slice([
          window.location.pathname.indexOf('/', 1) + 1,
        ])
      )
    );
    dispatch(fetchCompany());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Layout>
      <Grid container xs={12} className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={7}>
          <SideDrawer />
          <Card className={classes.card}>
            <CardMedia className={classes.media} image={headerImage || ''} />
            <CardContent>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '78%' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <Chip
                        key={job_offer.id}
                        className={classes.categoryChip}
                        label={displayCategory(job_offer.category)}
                        variant='outlined'
                        size='small'
                      />
                      <span style={{ margin: 8 }}></span>
                      {job_offer.publish ? (
                        <Button
                          variant='outlined'
                          disabled
                          style={{
                            color: '#3f51b5',
                            border: '1px solid rgba(63, 81, 181, 0.5)',
                          }}
                        >
                          公開中
                        </Button>
                      ) : (
                        <Button
                          variant='outlined'
                          disabled
                          style={{
                            color: '#f50057',
                            border: '1px solid rgba(245, 0, 87, 0.5)',
                          }}
                        >
                          非公開
                        </Button>
                      )}
                    </div>

                    <div className={classes.editPos}>
                      <Button
                        variant='outlined'
                        aria-label='edit'
                        href={`/recruit/${job_offer.id}/edit`}
                      >
                        <EditIcon style={{ fontSize: 16, marginRight: 3 }} />
                        求人情報を編集
                      </Button>
                      <span style={{ margin: '0 8px' }}></span>
                      <Button
                        variant='outlined'
                        aria-label='edit'
                        onClick={dialogOpenHandler}
                      >
                        <DeleteIcon style={{ fontSize: 24, marginRight: 3 }} />
                        求人情報を削除
                      </Button>
                    </div>
                  </div>

                  <Typography
                    gutterBottom
                    variant='h6'
                    component='h2'
                    className={classes.jobOfferTitle}
                  >
                    {job_offer.title}
                  </Typography>
                  <Table jobOffer={job_offer} />
                </div>

                <div className={classes.companyEasyProfileWrapper}>
                  <img
                    src={profileImage}
                    style={{
                      width: 182,
                      height: 182,
                      objectFit: 'cover',
                      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 20px 40px -14px',
                    }}
                    alt='Company Logo'
                  />
                  <h4 style={{ width: 188, fontSize: 15, marginBottom: 0 }}>
                    {company.name}
                  </h4>
                  <p style={{ width: 188, fontSize: 10 }}>{company.address}</p>
                  <p className={classes.companyIntroduction}>
                    {company.introduction}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showDialog && <DeleteDialog context={dialogContext} />}
    </Layout>
  );
};

export default RecruitShow;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';

import { Link } from '../common/Link';

import DefaultProfileImage from '../../images/default_images/default_profile_image.png';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  flex_center: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    marginTop: 24,
    width: '100%',
    marginRight: 30,
    overflow: 'unset',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 20px 40px -14px',
    borderRadius: 0
  },
  name: {
    fontSize: '1.1rem',
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '17px'
  },
  paragraph: {
    maxWidth: '200px',
    fontSize: '0.9rem',
    marginBottom: '10px',
    wordWrap: 'break-word'
  },
  media: {
    height: 200
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const Profile2 = props => {
  const { id } = props.student;
  const { status } = props.student.attributes;
  const {
    name,
    age,
    image,
    course,
    self_introduction,
    desired_workplace_location
  } = props.student.attributes.student_info;
  const children = props.children;

  const classes = useStyles();

  const displayStudentStatus = status => {
    return status === 'graduate' ? '卒業生' : '受講中';
  };

  const studentStatusBgColor = status => {
    return status === 'graduate' ? '#A8D8B9' : '#F8C3CD';
  }

  return (
    <Card className={classes.card}>
      <div className={classes.flex} style={{ marginBottom: 32 }}>
        <Grid item xs={12} style={{ width: 256, height: 608 }}>
          <Typography
            gutterBottom
            variant='h6'
            component='h6'
            className={classes.name}
            style={{
              justifyContent: 'space-between',
              padding: '16px 8px 0 8px',
              marginBottom: 0
            }}
          >
            {/* slice(0, 12)はコース名が長いときに消す用
            今のところ機械学習エンジニアコースとWEBエンジニアコースしか想定していないので
            0, 12でいけているけど、海外のコース名はだいたいかなり長いので
            海外展開するのであればここはあたらめて考えなければいけない */}
            <Chip
              key={course.id}
              label={course.name.slice(0, 12)}
              variant='outlined'
              size='small'
            />{' '}
            {children}
          </Typography>
          <Link to={`student_detail/${id}`}>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              style={{
                marginTop: 6,
                marginLeft: 13,
                fontSize: 14,
                background: studentStatusBgColor(status),
                width: 'fit-content',
                padding: '0 15px',
                borderRadius: 25
              }}
            >
              {displayStudentStatus(status)}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px 0'
              }}
            >
              <img
                src={image ? image : DefaultProfileImage}
                style={{
                  width: '138px',
                  height: '138px',
                  borderRadius: '50%',
                  objectFit: 'cover'
                }}
                alt='Job Seeker Face'
              />
            </div>
            <CardContent
              style={{ padding: 0, display: 'flex', justifyContent: 'center' }}
            >
              <Typography
                component='p'
                className={classes.name}
                style={{ padding: 0 }}
              >
                <span style={{ fontWeight: 'bold' }}>{name}</span>
                <span style={{ margin: '0 4px' }}></span>
                <span
                  style={{
                    fontSize: 15,
                    display: 'flex',
                    alignItems: 'flex-end'
                  }}
                >
                  {age && `${age}歳`}
                </span>
              </Typography>
            </CardContent>
            <CardContent
              style={{
                padding: '14px 0 6px 0',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div>
                <Typography
                  component='p'
                  className={classes.paragraph}
                  style={{
                    width: 'auto',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  希望勤務地:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {desired_workplace_location === null ||
                    desired_workplace_location === ''
                      ? '________'
                      : `${desired_workplace_location}`}
                  </span>
                </Typography>
              </div>
            </CardContent>
            <CardContent
              style={{
                padding: '0 0 10px 0',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Typography component='p' className={classes.paragraph}>
                { self_introduction ? self_introduction : '入力準備中...' }
              </Typography>
            </CardContent>
          </Link>
        </Grid>
      </div>
    </Card>
  );
};

export default Profile2;

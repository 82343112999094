import { combineReducers } from 'redux';
import { reduxTokenAuthReducer } from 'redux-token-auth';
import { users } from './users';
import { company, unread_count } from './company';
import {
  students,
  newRegisterdStudents,
  student,
  search_students
} from './students';
import { job_offers, job_offer } from './jobOffers';
import { recruitment_items } from './recruitItems';
import { message_flg, chat_flg } from './message';
import { chat_room, inboxes, messages, search_inboxes } from './chat';
import { connectRouter } from 'connected-react-router';

export default history =>
  combineReducers({
    router: connectRouter(history),
    users,
    company,
    unread_count,
    students,
    newRegisterdStudents,
    student,
    search_students,
    job_offers,
    job_offer,
    recruitment_items,
    chat_room,
    search_inboxes,
    inboxes,
    messages,
    message_flg,
    chat_flg,
    reduxTokenAuth: reduxTokenAuthReducer
  });

import React from 'react';
import clix from 'clsx';

import {
  makeStyles,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';
import DropZoneImage from '../JobOffer/DropZoneImage';
import DatePicker from '../common/DatePicker';

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  buttonArea: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  dropAreaHeader: values => ({
    backgroundImage: `url(${values.header_image && values.header_image.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    width: 1111,
    minHeight: 222,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  dropAreaProfileImage: values => ({
    backgroundImage: `url(${values.profile_image && values.profile_image.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: 182,
    minHeight: 182,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover'
  }),
  inputRoot: {
    fontSize: 16,
    paddingTop: 8,
  },
  labelRoot: {
    fontSize: 18,
    fontWeight: 600
  },
  labelRootRequired: {
    color: 'rgb(226 50 90)',
    fontSize: 18,
    fontWeight: 600
  },
  labelFocused: {},
  updateButton: {
    height: 56,
    width: 216,
    borderRadius: 25,
    fontWeight: 600,
  },
  companyEasyProfileWrapper: {
    width: '22%',
    paddingTop: 24,
    paddingLeft: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  required: {
    color: '#f50057',
  }
}));

const InputAdornments = props => {
  const {
    values,
    inputValueUpdateHandler,
    updateHandler,
    dispatcher,
    imageChangeHandler,
    history
  } = props;

  const classes = useStyles(values);

  const handleChange = prop => event => {
    dispatcher(
      inputValueUpdateHandler({ ...values, [prop]: event.target.value })
    );
  };

  const handleDateChange = prop => event => {
    dispatcher(inputValueUpdateHandler({ ...values, [prop]: event }));
  };

  const handleUpdate = () => {
    updateHandler({ ...values });
    values.name.length > 0 && history.push('/company');
  };

  return (
    <>
      <FormControl fullWidth className={classes.formControl}>
        <DropZoneImage
          dropzoneClass={classes.dropAreaHeader}
          acceptedFiles={['image/jpeg', 'image/png']}
          maxFileSize={5000000}
          imageHandler={imageChangeHandler('header_image')}
          imageSizeDesc={'222 × 1111'}
        />
        <p style={{ margin: 0, fontSize: 16, width: 1111, textAlign: 'right', fontWeight: 600 }}>
          1111 × 222 
        </p>
      </FormControl>
      <div style={{ width: 1111, display: 'flex' }}>
        <div style={{ width: '78%'}}>
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              id="standard-start-adornment"
              name="name"
              value={values.name || ''}
              required
              error={values.errors && values.errors.error && values.errors.error.name}
              helperText={
                values.errors && values.errors.error && values.errors.error.name
              }
              onChange={handleChange('name')}
              variant="outlined"
              label="会社名 (必須)"
              InputLabelProps={{
                shrink: true,
                className: classes.required,
              }}
            />
          </FormControl>

          <div style={{ margin: '8px 0' }}></div>

          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              name="description"
              value={values.description}
              onChange={handleChange('description')}
              variant="outlined"
              label="事業内容"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rowsMax="10"
            />
          </FormControl>

          <div style={{ margin: '8px 0' }}></div>
  
          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              name="address"
              value={values.address || ''}
              onChange={handleChange('address')}
              variant="outlined"
              label="本社所在地"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <div style={{ margin: '8px 0' }}></div>

          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              name="address"
              value={values.rep_name || ''}
              onChange={handleChange('rep_name')}
              variant="outlined"
              label="代表者氏名"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <div style={{ margin: '8px 0' }}></div>

          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              name="home_page_url"
              value={values.home_page_url || ''}
              onChange={handleChange('home_page_url')}
              variant="outlined"
              label="ホームページ"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>

          <FormControl fullWidth className={classes.margin} variant="outlined" style={{ padding: 8 }}>
            <DatePicker
              placeholder="設立年月日"
              name="founded_date"
              value={values.founded_date || ''}
              selectDate={values.founded_date}
              setSelectState={handleDateChange}
              format={"yyyy/MM/dd"}
              openTo={'year'}
              views={['year', 'month', 'date']}
              minDate={new Date('1950-01-01')}
              maxDate={new Date()}
            />
          </FormControl>

          <FormControl fullWidth className={classes.margin}>
            <TextField
              label="従業員数 (単位: 名)"
              className={clix(classes.margin, classes.textField)}
              onChange={handleChange('employee_number')}
              type="number"
              value={values.employee_number || ''}
            />
          </FormControl>

          <FormControl fullWidth className={classes.margin}>
            <TextField
              label="資本金 (単位: 万円)"
              id="standard-start-adornment"
              className={clix(classes.margin, classes.textField)}
              onChange={handleChange('capital')}
              type="number"
              value={values.capital || ''}
            />
          </FormControl>

          <div style={{ margin: '8px 0' }}></div>

          <FormControl fullWidth className={classes.margin} variant="outlined">
            <TextField
              name="main_stock_holder"
              value={values.main_stock_holder}
              onChange={handleChange('main_stock_holder')}
              variant="outlined"
              label="主要株主"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rowsMax="10"
            />
          </FormControl>

          <div style={{ margin: '32px 0' }}></div>

          <div style={{ display: 'flex', justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              aria-label="edit"
              onClick={handleUpdate}
              className={classes.updateButton}
            >
              会社情報を更新
            </Button>
          </div>
        </div>

        <div className={classes.companyEasyProfileWrapper}>
          <DropZoneImage
            dropzoneClass={classes.dropAreaProfileImage}
            acceptedFiles={['image/jpeg', 'image/png']}
            maxFileSize={5000000}
            imageHandler={imageChangeHandler('profile_image')}
            imageSizeDesc={'182× 182'}
          />
          <div>
          <p style={{ margin: 0, width: 182, textAlign: 'right', fontWeight: 600 }}>
            182 × 182
          </p>
          </div>
          <div style={{ margin: '10px 0' }}></div>
          <FormControl className={classes.margin} variant="outlined">
            <TextField
              name="introduction"
              value={values.introduction}
              onChange={handleChange('introduction')}
              variant="outlined"
              label="企業紹介文"
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              placeholder={`- Mission\n\n\n\n- Vision\n\n\n\n- Value`}
              rows="26"
            />
          </FormControl>
        </div>
      </div>
    </>
  );
};

export default InputAdornments;

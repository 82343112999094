import chatApi from '../api/chat';
export const CHAT_ROOM = 'CHAT_ROOM';
export const INBOXES = 'INBOXES';
export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const SEARCH_INBOXES = 'SEARCH_INBOXES';
export const ADD_INBOXES = 'ADD_INBOXES';
export const WAITING_MESSAGE = 'WAITING_MESSAGE';

export function createChatRoom(userId) {
  return async dispatch => {
    const res = await chatApi.createChatRoom(userId);
    dispatch(receiveChatRoom(res.data.data));
  };
}

export function getInbox() {
  return async dispatch => {
    const res = await chatApi.getInbox();
    dispatch(receiveInboxes(res.data.data));
  };
}

export function createChatMessage(data) {
  return async dispatch => {
    const res = await chatApi.createMessage(data);
    dispatch(receiveMessage(res.data.data));
    dispatch(addMessageToInboxes(res.data.data));
  };
}

export function setWaitingMessage() {
  return dispatch => {
    dispatch(receiveWaitingMessage({ id: -9999 }));
  };
}

export function fetchChatMessages(chatRoomId) {
  return async dispatch => {
    const res = await chatApi.fetchChatMessages(chatRoomId);
    dispatch(receiveMessage(res.data.data));
  };
}

export function searchInbox(query, inboxes) {
  return dispatch => {
    dispatch(search(query, inboxes));
  };
}

function receiveChatRoom(room) {
  return {
    type: CHAT_ROOM,
    room
  };
}

function receiveInboxes(inboxes) {
  return {
    type: INBOXES,
    inboxes
  };
}

function addMessageToInboxes(messages) {
  return {
    type: ADD_INBOXES,
    messages
  };
}

function receiveMessage(messages) {
  return {
    type: CHAT_MESSAGE,
    messages
  };
}

function receiveWaitingMessage(messages) {
  return {
    type: WAITING_MESSAGE,
    messages
  };
}

function search(query, inboxes) {
  return {
    type: SEARCH_INBOXES,
    query: query,
    inboxes: inboxes
  };
}

import { MESSAGE_TOGGLE, CHAT_TOGGLE } from "../actions/message";

export function message_flg(state = false, action) {
  switch (action.type) {
    case MESSAGE_TOGGLE:
      return !state;
    default:
      return state;
  }
}

export function chat_flg(state = false, action) {
  switch (action.type) {
    case CHAT_TOGGLE:
      return !state;
    default:
      return state;
  }
}

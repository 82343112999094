import companyApi from '../api/company';
import chatApi from '../api/chat';
export const COMPANY = 'COMPANY';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const UNREAD_NOTICE_COUNT = 'UNREAD_NOTICE_COUNT';
export const BOOL_TYPE_EMPLOYEE_CONF_TOGGLED = 'BOOL_TYPE_EMPLOYEE_CONF_TOGGLED';
export const UNDEFINED_ERROR = 'UNDEFINED_ERROR';

export function fetchCompany() {
  return async (dispatch) => {
    try {
      const res = await companyApi.getOwn();
      dispatch(receiveCompany(res.data.data));
      return res;
    } catch (err) {
      dispatch(undefinedError());
    }
  };
}

export function fetchUnreadNotice() {
  return async (dispatch) => {
    try {
      const res = await chatApi.fetchUnreadCount();
      dispatch(receiveUnreadNoticeCount(res.data));
    } catch (err) {
      dispatch(undefinedError());
    }
  };
}

export function updateCompany(data) {
  return async (dispatch) => {
    try {
      const res = await companyApi.updateCompany(data);
      dispatch(receiveCompany(res.data.data));
    } catch (err) {
      dispatch(undefinedError());
    }
  };
}

export function updateEmployee(data) {
  return async (dispatch) => {
    try {
      const res = await companyApi.updateCompany(data);
      dispatch(receiveCompany(res.data.data));
    } catch (err) {
      dispatch(undefinedError());
    }
  };
}

export function editCompanyForm(data) {
  return (dispatch) => {
    dispatch(receiveCompany({ attributes: { ...data } }));
  };
}

export function toggleBoolTypeEmployeeConf(confId, key) {
  return async dispatch =>{
    try {
      const res = await companyApi.toggleBoolTypeEmployeeConf(confId, key);
      dispatch(receiveBoolTypeEmployeeConfToggled(res.data));
    } catch (error) {
      dispatch(undefinedError());
    }
  };
}

function receiveCompany(company) {
  return {
    type: COMPANY,
    payload: { company },
  };
}

function receiveUnreadNoticeCount(unreadNoticeCount) {
  return {
    type: UNREAD_NOTICE_COUNT,
    payload: { unreadNoticeCount },
  };
}

function receiveBoolTypeEmployeeConfToggled(data) {
  return {
    type: BOOL_TYPE_EMPLOYEE_CONF_TOGGLED,
    payload: { data },
  };
}

function undefinedError() {
  return {
    type: UNDEFINED_ERROR,
  };
}

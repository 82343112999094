import recruitmentItemApi from "../api/recruitmentItem";

export const RECRUIT_ITEMS = "RECRUIT_ITEMS";

export function fetchRecruitmentItems() {
  return async (dispatch) => {
    const res = await recruitmentItemApi.getAll();
    dispatch(receiveRecruitmentItems(res.data));
  };
}

function receiveRecruitmentItems(recruitment_items) {
  return {
    type: RECRUIT_ITEMS,
    recruitment_items
  };
}

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import {
  makeStyles,
  Container,
  Grid,
  TablePagination,
  Button,
} from '@material-ui/core';

import { createFavorite, deleteFavorite } from '../../actions/students';

import japan from '../../utils/DesiredWorkplaceLocations';

import Layout from '../../components/Layout';
import PagenationActionItem from './../common/PagenationActionItem';
import IntroductionDialog from './../common/IntroductionDialog';
import BookMark from './BookMark';
import Profile2 from './StudentCard2.jsx';
import StatusCheckBox from '../common/CheckBox';
import StatusSelectBox from '../common/SelectBox';
import DatePicker from '../common/DatePicker';
import SelectItem from '../common/SelectItem';
import SideDrawer from '../common/Drawer';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import usePaginationState from '../../state/ui/usePagenationState';
import { googleAnalytics } from '../../utils/pageGA';

const selectUser = (students, search_students) => {
  return search_students && search_students.length > 0
    ? search_students
    : students;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  containerGrid: {
    justifyContent: 'center',
  },
  title: {
    width: '100%',
  },
  container: {
    display: 'flex',
    direction: 'column',
    justify: 'center',
    alignItems: 'center',
    width: 1000,
    margin: '0 0 0 3%',
    padding: 0,
  },
  drawer: {
    width: '240px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
    backgroundColor: '#fafafa',
    borderRight: ['none'],
    marginLeft: '2%',
    marginTop: '4%',
  },
  profile: {
    width: '100%',
    position: 'relative',
  },
  card: {
    display: 'flex',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: 1000,
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
  listHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  fiexdForm: {
    position: 'fixed',
    right: 0,
  },
  paragraph: {
    marginLeft: '20px',
    marginBottom: '5px',
    fontSize: '0.8rem',
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'column',
  },
  test: {
    width: 256,
  },
}));

const ageObj = {
  none: {
    name: '選択なし',
    value: '',
  },
  twenty: {
    name: '18~29',
    value: 18,
  },
  thirty: {
    name: '30~39',
    value: 30,
  },
  forty: {
    name: '40~49',
    value: 40,
  },
  fifty: {
    name: '50~',
    value: 50,
  },
};

const OfferingStudents = (props) => {
  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePaginationState();

  const dispatch = useDispatch();

  // const booleanConvert = (boolStr) => (boolStr === 'true' ? true : false);

  const [checkState, setCheckState] = useState({
    WEB: false,
    ML: false,
    graduate: false,
    inSchool: false,
  });
  const [selectState, setSelectState] = useState({
    graduateDate: null,
    age: '',
    address: '',
    amount: '',
  });
  const [textState, setTextState] = useState({
    name: '',
  });

  const handleCheckChange = (name) => (event) => {
    setCheckState({ ...checkState, [name]: event.target.checked });
  };

  const handleSelectDateChange = (name) => (event) => {
    setSelectState({ ...selectState, [name]: event });
  };

  const handleSelectChange = (name) => (event) => {
    setSelectState({ ...selectState, [name]: event.target.value });
  };

  const setCalenderDate = (yearKey = -2, monthKey = 0) => {
    return `${new Date().getFullYear() + yearKey}-${
      new Date().getMonth() + monthKey
    }-${new Date().getDate()}`;
  };

  const handleStudentNameInput = (name) => (event) => {
    setTextState({ ...textState, [name]: event.target.value});
  }

  const searchHandler = () => {
    googleAnalytics({
      pathname: `${history.location.pathname}/${history.location.search}`,
    });
    const mergeQuery = Object.assign(checkState, selectState, textState);
    history.push({
      search: new URLSearchParams({
        pageNum: 0,
        rowPerPage: 9,
        ...mergeQuery,
      }).toString(),
    });
    fetchStudents([0, 9, mergeQuery]);
  };
  const {
    students,
    search_students,
    fetchStudents,
    users_size,
    location,
    history,
  } = props;

  const showIntroduction =
    props.location.state && props.location.state.showIntroduction;

  const classes = useStyles();

  const handleFetchPage = (event, pageNum) => {
    // const prevQuery = queryString.parse(location.search);

    const mergeQuery = Object.assign(checkState, selectState);
    const nextPage = pageNum + 1;
    history.push({
      search: new URLSearchParams({
        pageNum: nextPage,
        rowPerPage: rowsPerPage,
        ...mergeQuery,
      }).toString(),
    });
    handleChangePage(event, pageNum);

    fetchStudents([nextPage, rowsPerPage, mergeQuery]);
    window.scrollTo({ top: 0 });
  };

  const handleFetchRowsPerPage = (event) => {
    handleChangeRowsPerPage(event);
    const mergeQuery = Object.assign(checkState, selectState);
    history.push({
      search: new URLSearchParams({
        pageNum: 0,
        rowPerPage: event.target.value,
        ...mergeQuery,
      }).toString(),
    });
    fetchStudents([0, event.target.value, mergeQuery]);
  };

  const favoriteHandle = (prop) => () => {
    prop.attributes.student_info.isBookMark
      ? dispatch(
          deleteFavorite({
            studentId: prop.attributes.student_info.user_id,
            favoriteId: prop.attributes.student_info.favorite_id,
          })
        )
      : dispatch(
          createFavorite({
            studentId: prop.attributes.student_info.user_id,
            callLocation: 'index',
          })
        );
  };

  const prefectureObj = japan.japan.reduce((prev, current) => {
    prev[`${current}`] = { name: current, value: current };
    return prev;
  }, {});

  const handlePrevLocationSet = (state, setHandler, prevQuery, stateType = "normal") => {
    setHandler(Object.keys(state).reduce((prev, current) => {
      prev[current] = typeConvert(prevQuery[current], stateType)
      return prev} ,{}))
  }
  const typeConvert = (value, stateType) => {
    if (stateType === "normal") {
      if (value === 'true' || value === 'false' || value === 'undefined') {
        return text_to_bool(value)
      }
    } else {
      if (value === 'undefined') {
        return ""
      }
    }

    return value
  }
  const text_to_bool = (tf) => tf === 'true';

  useEffect(() => {
    const prevQuery = queryString.parse(location.search);

    handlePrevLocationSet(checkState, setCheckState, prevQuery)
    handlePrevLocationSet(selectState, setSelectState, prevQuery, "specify")
    handlePrevLocationSet(textState, setTextState, prevQuery, "specify")
    handleChangePage(
      {},
      prevQuery['pageNum'] === undefined || +prevQuery['pageNum'] < 1
        ? 0
        : parseInt(prevQuery['pageNum'] - 1)
    );
  }, []);

  return (
    <Layout>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item xs={12} sm={7}>
          <SideDrawer
            isShowManage={
              props.location.search === '?favorite=true' ? false : true
            }
          >
            {props.location.search === '?favorite=true' ? null : (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 0,
                  }}
                >
                  <SearchIcon />
                  <p
                    style={{
                      margin: '0 0 0 2px',
                      fontSize: 18,
                      fontWeight: 'bold',
                    }}
                  >
                    求職者検索
                  </p>
                  <div
                    style={{
                      backgroundColor: 'rgba(63,53,56,1)',
                      fontSize: 12,
                      color: 'white',
                      padding: '1.5px 4px',
                      margin: '.5rem',
                    }}
                  >
                    version β
                  </div>
                </div>
                <StatusCheckBox
                  checkState={checkState}
                  setCheckState={handleCheckChange}
                />
                <TextField
                  label="求職者名"
                  onChange={handleStudentNameInput('name')}
                  style={{ margin: '16px 27px 16px 16px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={textState.name}
                />
                <StatusSelectBox labelTitle='' style={{ marginTop: 16 }}>
                  <DatePicker
                    name='graduateDate'
                    placeholder='卒業予定月'
                    selectDate={selectState.graduateDate}
                    setSelectState={handleSelectDateChange}
                    format={'yyyy/MM'}
                    openTo={'month'}
                    views={['year', 'month']}
                    minDate={setCalenderDate()}
                    maxDate={setCalenderDate(2)}
                  />
                </StatusSelectBox>
                <StatusSelectBox labelTitle=''>
                  <div
                    style={{ color: 'rgb(117 117 117)', fontSize: '0.75rem' }}
                  >
                    年齢
                  </div>
                  <SelectItem
                    targetObj={ageObj}
                    selectObj={selectState.age}
                    setSelectState={handleSelectChange('age')}
                  />
                </StatusSelectBox>
                <StatusSelectBox labelTitle='' selectedFlg={selectState}>
                  <div
                    style={{ color: 'rgb(117 117 117)', fontSize: '0.75rem' }}
                  >
                    希望勤務地
                  </div>
                  <SelectItem
                    targetObj={prefectureObj}
                    selectObj={selectState.address}
                    setSelectState={handleSelectChange('address')}
                  />
                </StatusSelectBox>
                <Button
                  variant='outlined'
                  size='large'
                  color='primary'
                  className={classes.margin}
                  onClick={searchHandler}
                  style={{
                    width: 196,
                    borderRadius: 40,
                    marginTop: 24,
                    marginLeft: 16,
                  }}
                >
                  検索
                </Button>
              </>
            )}
          </SideDrawer>

          <Container className={classes.container}>
            <Grid
              className={classes.content}
              style={
                students.length === 2
                  ? { justifyContent: 'space-around' }
                  : null
              }
            >
              {students.length !== 0
                ? selectUser(students, search_students).map(
                    (student, index) => {
                      return (
                        <div key={index}>
                          <div className={classes.profile}>
                            <Grid>
                              <Profile2 student={student}>
                                <BookMark
                                  student={student.attributes.student_info}
                                  favoriteHandler={favoriteHandle(student)}
                                  classes={classes}
                                />
                              </Profile2>
                            </Grid>
                          </div>
                        </div>
                      );
                    }
                  )
                : null}
            </Grid>
            {showIntroduction && <IntroductionDialog />}
          </Container>
          <TablePagination
            rowsPerPageOptions={[9, 24]}
            labelRowsPerPage='表示件数'
            colSpan={3}
            count={users_size}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleFetchPage}
            onChangeRowsPerPage={handleFetchRowsPerPage}
            ActionsComponent={PagenationActionItem}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to}件/${count}件`
            }
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default OfferingStudents;

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
} from '@material-ui/core';
import {
  toggleBoolTypeEmployeeConf,
} from '../../actions/company';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { deleteEmployee, updateEmployee } from '../../actions/users';

const useStyles = makeStyles(theme => ({
  textField: {
    width: 200
  }
}));

const EmployeeList = props => {
  const dispatch = useDispatch();

  const [employees, setEmployee] = useState(
    props.users.map(user => ({ ...user, edit: false }))
  );
  // const displayRole = position => {
  //   return Number(position) === 2 ? '管理者' : '社員';
  // };
  // const editHandler = e => {
  //   const Id = e.currentTarget.value;
  //   setEmployee(
  //     employees.map(employee => {
  //       if (employee.id === +Id) {
  //         employee.edit = true;
  //       }
  //       return employee;
  //     })
  //   );
  // };
  const editValueHandler = prop => e => {
    const [key, Id] = prop;
    setEmployee(
      employees.map(employee => {
        if (employee.id === +Id) {
          return {
            ...employee,
            [key]: e.target.value
          };
        }
        return employee;
      })
    );
  };
  const notify = () => {
    toast.info('設定を保存しました', {
      position: "top-center",
      autoClose: 2222,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      transition: Slide
    });
  };
  const toggleBoolTypeConfHandler = (confId, key) => {
    return () => {
      dispatch(toggleBoolTypeEmployeeConf(confId, key));
      console.log("come!!!!!")
      notify();
    };
  };
  const classes = useStyles();

  // const updateHandler = e => {
  //   // 表示文字の親要素のvalueにuserのidがあるため取得
  //   const data = employees.find(
  //     employee => employee.id === +e.target.parentElement.value
  //   );
  //   dispatch(updateEmployee({ data }));

  //   const searchedEmployee = data;
  //   setEmployee(
  //     employees.map(employee => {
  //       if (employee.id === +searchedEmployee.id) {
  //         return {
  //           ...searchedEmployee,
  //           edit: false
  //         };
  //       }
  //       return employee;
  //     })
  //   );
  // };
  // const deleteHandler = e => {
  //   const Id = e.currentTarget.value;
  //   dispatch(deleteEmployee(Id));
  // };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{fontWeight: 600}}>名前</TableCell>
          <TableCell style={{fontWeight: 600}}>メールアドレス</TableCell>
          <TableCell style={{fontWeight: 600}} align={'center'}>メール受信設定</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tableContainer}>
        <ToastContainer
          position="top-center"
          autoClose={2222}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          transition={Slide}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {employees.map((record, index) => (
          <TableRow key={index}>
            {record.edit === true ? (
              <>
                <TableCell>
                  <TextField
                    id="standard-start-adornment"
                    className={classes.textField}
                    onChange={editValueHandler(['name', record.id])}
                    value={record.name}
                  />
                </TableCell>
                {/* <TableCell>
                  <TextField
                    id="standard-start-adornment"
                    className={classes.textField}
                    onChange={editValueHandler(['position', record.id])}
                    value={displayRole(record.position)}
                  />
                </TableCell> */}
                <TableCell>
                  <TextField
                    id="standard-start-adornment"
                    className={classes.textField}
                    onChange={editValueHandler(['email', record.id])}
                    value={record.email}
                  />
                </TableCell>
              </>
            ) : (
              <>
                <TableCell>{record.name}</TableCell>
                {/* <TableCell>{displayRole(record.position)}</TableCell> */}
                <TableCell>{record.email}</TableCell>
                <TableCell align={'center'}>
                  { record.conf !== null && record.conf.is_mail_receiving !== null &&
                    <Checkbox
                      defaultChecked
                      color="primary"
                      checked={record.conf.is_mail_receiving}
                      onClick={toggleBoolTypeConfHandler(record.conf.id, 'is_mail_receiving')}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                </TableCell>
              </>
            )}
            {/* <TableCell>
              <Button
                variant="contained"
                color="primary"
                value={record.id}
                onClick={record.edit === true ? updateHandler : editHandler}
              >
                {record.edit === true ? '更新' : '編集'}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                value={record.id}
                onClick={deleteHandler}
              >
                削除
              </Button>
            </TableCell> */}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EmployeeList;

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { fetchStudent } from '../../actions/students';
import { toggleForm } from '../../actions/message';
import { toggleModal } from '../../actions/util';
import { createChatRoom } from '../../actions/chat';
import StudentDetail from '../../components/StudentDetail';
import HocIndicator from '../../components/common/HocIndicator';
import { googleAnalytics } from '../../utils/pageGA';

const mapStateToProps = (state) => ({
  student: state.student,
  indicatorFlg: true,
  open: state.modal,
  currentUser: state.reduxTokenAuth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchStudent: (id) => dispatch(fetchStudent(id)),
  toggleForm: () => dispatch(toggleForm()),
  toggleModal: () => dispatch(toggleModal()),
  createChatRoom: (userId) => dispatch(createChatRoom(userId)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const { fetchStudent } = this.props;
      googleAnalytics({
        pathname: this.props.history.location.pathname,
      });
      // 前のページのidを取得していたのでparamsから取得するように変更
      fetchStudent(this.props.match.params.id)
        .then((res) => {
          if (!!res) throw new Error(res.name);
          this.setState({ indicatorFlg: false });
        })
        .catch((err) => {
          this.props.history.replace('/offering_students');
          this.props.history.push('/Error');
        });
    },
  })
)(HocIndicator(StudentDetail));

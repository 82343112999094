import { useState } from 'react';

export default () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  return {
    page,
    rowsPerPage,
    handleChangePage: (event, newPage) => {
      setPage(newPage);
    },
    handleChangeRowsPerPage: event => {
      setRowsPerPage(event.target.value);
    }
  };
};

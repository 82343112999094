import API_CLIENT from "../utils/HttpClient";

export default {
  updateInvitable(data) {
    return API_CLIENT({
      method: "PUT",
      url: "/auth/invitation",
      data: data
    });
  },
  addInvitable(data, company_id) {
    const formData = new FormData();
    formData.append("recruit_employee[name]", data.name);
    formData.append("recruit_employee[email]", data.email);
    formData.append("recruit_employee[recruit_company_id]", company_id);
    return API_CLIENT({
      method: "POST",
      url: "/api/v2/recruit/invitations/add",
      data: formData
    })
  },
}
